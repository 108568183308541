import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteDataService {
  public readonly data$: Observable<any>;

  private readonly dataEmitter = new BehaviorSubject<any>({});

  public constructor() {
    this.data$ = this.dataEmitter
      .asObservable();
  }

  public push(data: any) {
    this.dataEmitter.next(data);
  }
}
