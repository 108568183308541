import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import { FormKeys as NestedFormKeys, ValidationKeys } from '../new-password-fields-section/new-password-fields-section.component';
import { PasswordStrengthService } from '@security/change-password';
import { ChangePasswordPayload } from '@security/change-password';

enum FormKeys {
  CURRENT_PASSWORD = 'currentPassword'
}

export interface FormValue {
  [FormKeys.CURRENT_PASSWORD]: string;
  [NestedFormKeys.NEW_PASSWORD]: string;
  [NestedFormKeys.NEW_PASSWORD_CONFIRMATION]: string;
}

@Component({
  selector: 'ic-password-form',
  templateUrl: './password-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordFormComponent implements OnInit, OnDestroy {
  @Output()
  public save = new EventEmitter<ChangePasswordPayload>();
  @Output()
  public changed = new EventEmitter<void>();

  public formGroup: FormGroup;
  public submitted: boolean;
  public FormKeys = FormKeys;

  private subscriptions = new Subscription();

  public constructor(
    private formBuilder: FormBuilder,
    private passwordStrength: PasswordStrengthService
  ) {
    this.createForm();
  }

  @Input()
  public set completed(value: boolean) {
    if (!value) { return; }

    this.reset();
  }

  public ngOnInit() {
    this.subscribeToFormChanges();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) { return; }

    const value: FormValue = this.formGroup.value;

    this.save.emit({
      currentPassword: value[FormKeys.CURRENT_PASSWORD],
      newPassword: value[NestedFormKeys.NEW_PASSWORD]
    });
  }

  public controlHasError(controlName: FormKeys | NestedFormKeys, validatorName?: ValidationKeys) {
    const formControl = this.formGroup.get(controlName);
    const isInvalid = !!formControl.errors
      && (!validatorName || !!formControl.errors[validatorName.toLowerCase()]);

    return formControl.invalid && isInvalid && (!formControl.pristine || this.submitted);
  }

  public reset() {
    this.submitted = false;

    this.formGroup.reset({}, { emitEvent: false });
    this.formGroup.markAsPristine();
  }

  private createForm() {
    this.formGroup = this.formBuilder.group({
      [FormKeys.CURRENT_PASSWORD]: ['', Validators.required],
      [NestedFormKeys.NEW_PASSWORD]: ['', [Validators.required, this.strongPassword.bind(this)]],
      [NestedFormKeys.NEW_PASSWORD_CONFIRMATION]: ['', [Validators.required]]
    });
  }

  private strongPassword(control: AbstractControl) {
    if (!control.value) { return null; }

    return this.passwordStrength.validate(control.value)
      ? null
      : { [ValidationKeys.STRONG_PASSWORD]: true };
  }

  private subscribeToFormChanges() {
    const subscription = this.formGroup.valueChanges
      .pipe(
        debounceTime(250)
      )
      .subscribe(() => this.changed.emit());

    this.subscriptions.add(subscription);
  }
}
