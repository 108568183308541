import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import {
  PolicyDEComponent, PolicyGBComponent,
  PolicyHRComponent,
  PolicyITComponent,
  PolicyPLComponent, PolicyROComponent
} from '@shared/disclaimer/policies';
import { NgIncludeDirective } from '@shared/layout';
import { CountryDetails, CountryResource } from '@shared/localization';
import { Observable, take } from 'rxjs';

@Component({
  template: `
    <ic-default-layout>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-top-15">
          <h3>
              {{ 'COOKIE_POLICY.TITLE' | translate }}
          </h3>

          <ic-country-selector
            [countries]="supportedCountries$ | async"
            (selected)="onCountrySelected($event)">
          </ic-country-selector>
        </div>
      </div>

      <!-- NOTE: this is the simplest way to ensure that cookie modal won't overlap the text on smaller screen -->
      <div class="row" style="margin-bottom: 150px;">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-top-15 padding-bottom-15">
          <div ngInclude></div>
        </div>
      </div>
    </ic-default-layout>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiePolicyPageComponent {
  @ViewChild(NgIncludeDirective, { static: true })
  public policyPlaceholder: NgIncludeDirective;

  public supportedCountries$: Observable<CountryDetails[]>;
  public country: string;

  public constructor(
    private countryResource: CountryResource
  ) {
    this.subscribeToState();
  }

  public onCountrySelected(country: string) {
    this.country = country;
    this.includePolicyByCountryCode(country);
  }

  private subscribeToState() {
    this.supportedCountries$ = this.countryResource.getCountries(true);
  }

  private includePolicyByCountryCode(country: string) {
    const viewContainer = this.policyPlaceholder.viewContainer;
    viewContainer.clear();

    const componentType = this.findTypeByCountryCode(country);

    if (!componentType) {
      this.country = null;
      throw new Error(`No policy found for selected country code: ${country}`);
    }

    viewContainer.createComponent(componentType);
  }

  private findTypeByCountryCode(country: string) {
    switch (country) {
      case 'DE': {
        return PolicyDEComponent;
      }
      case 'HR': {
        return PolicyHRComponent;
      }
      case 'IT': {
        return PolicyITComponent;
      }
      case 'PL': {
        return PolicyPLComponent;
      }
      case 'RO': {
        return PolicyROComponent;
      }
      case 'GB': {
        return PolicyGBComponent;
      }
      default: {
        return null;
      }
    }
  }
}
