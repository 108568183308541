import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { HandleHttpUnauthorizedInterceptor } from '@security/auth';
import { SecurityRoutingModule } from './security-routing.module';
import { LogoutComponent } from './logout';
import { NewPasswordFieldsSectionComponent, PasswordComponent, PasswordFormComponent } from './change-password';
import { EmailChangeFormComponent } from './change-email';
import { DefaultLayoutComponent, TopNavigationComponent } from './layout';
import { UserRegistrationComponent } from './registration';
import { ManageMfaComponent, MfaCredentialsComponent, MfaInstructionsComponent, SetUpMfaComponent } from './mfa';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    SecurityRoutingModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    NgbTooltipModule
  ],
  declarations: [
    LogoutComponent,
    NewPasswordFieldsSectionComponent,
    PasswordFormComponent,
    PasswordComponent,
    EmailChangeFormComponent,
    TopNavigationComponent,
    DefaultLayoutComponent,
    UserRegistrationComponent,
    ManageMfaComponent,
    SetUpMfaComponent,
    MfaCredentialsComponent,
    MfaInstructionsComponent
  ],
  exports: [
    DefaultLayoutComponent,
    NewPasswordFieldsSectionComponent,
    PasswordComponent,
    EmailChangeFormComponent,
    TopNavigationComponent,
    ManageMfaComponent
  ]
})
export class SecurityModule {
  public static forRoot(): ModuleWithProviders<SecurityModule> {
    return {
      ngModule: SecurityModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HandleHttpUnauthorizedInterceptor,
          multi: true
        }
      ]
    };
  }
}
