<div class="mfa-credentials-wrapper">
  <div class="row">
    <div class="col-xs-12 col-sm-11 col-sm-offset-1">
      <div class="mfa-credentials-list">
        <div *ngFor="let credential of credentials"
             class="mfa-credentials-list-item">
          <div class="mfa-icon">
            <i class="fa fa-mobile" style="font-size: 5.5rem;"></i>
          </div>
          <div class="mfa-credentials-list-item-content">
            <p>
              <strong>
                {{ translationPrefix.include('SETTINGS_MFA.CREDENTIALS.AUTHENTICATOR_APP.TITLE') | translate }}
              </strong>
            </p>
            <p>
              {{ translationPrefix.include('SETTINGS_MFA.CREDENTIALS.AUTHENTICATOR_APP.DESCRIPTION') | translate }}
            </p>

            <section>
              <div>
                <label>
                  {{ translationPrefix.include('SETTINGS_MFA.CREDENTIALS.DEVICE_NAME') | translate }}
                </label>
                <span>{{ credential.label || '---' }}</span>
              </div>
              <div>
                <label>
                  {{ translationPrefix.include('SETTINGS_MFA.CREDENTIALS.ADDED_ON') | translate }}
                </label>
                <span>{{ credential.creationDate | momentFormat$: 'DD.MM.YYYY' | async }}</span>
              </div>
              <div>
                <label>
                  {{ translationPrefix.include('DELETE') | translate }}
                </label>
                <button type="button" class="btn btn-link"
                        (click)="onRemoving(credential.id)">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
