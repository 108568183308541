import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DenyAnonymousGuard, EnsureTosAcceptedGuard, UserRole, requireRole } from '@security/user';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full',
  },
  {
    path: 'user',
    canActivate: [DenyAnonymousGuard, EnsureTosAcceptedGuard, requireRole(UserRole.PM)],
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'admin',
    canActivate: [DenyAnonymousGuard, EnsureTosAcceptedGuard, requireRole(UserRole.ADMIN)],
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'super-admin',
    canActivate: [DenyAnonymousGuard, EnsureTosAcceptedGuard, requireRole(UserRole.SUPER_ADMIN)],
    loadChildren: () => import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
