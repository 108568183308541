<h1>Privacy policy</h1>
<h2>1. General Conditions ista connect (UK)</h2>
<p>August 1st, 2014 <br>
	Ista Energy Solutions Limited <br>
	3 Riverside, Granta Park<br>
	Great Abington<br>
	CB21 6AD<br>
	Company Registration Number: 37525762<br>
	Phone: +44 (0) 1223 874 974</p>
(Hereinafter "ista") is the owner of this application. The use of this application and its content is subject to the following Terms and Conditions.
<h2>2. General Conditions for the Use of Application</h2>
<p>Users may not misuse the data and information, for example. Unauthorised entry of third party data.</p>
<p>Users may not misuse the application for any fraudulent activity or other criminal acts.</p>
<p>The user undertakes to exempt ista and Ista’s employees from any and all claims by third parties (including legal
	costs) which are attributable to a non-contractual, abusive, or otherwise illegal use of the application and its
	contents.</p>
<p>Ista has the right, at any time to block access to the application in the event that the user does not qualify for
	use of the application. ista reserves the right at any time to modify the application or its contents.</p>
<h2>3. Disclaimer</h2>
<p>Despite the utmost care in the establishment, operation and updating of the application and its contents ista assumes
	no liability for any damages or losses, directly or indirectly related to:</p>
<ul>
	<li>False information on the application (excluded are particularly responsible for the accuracy, completeness or
		timeliness of the information, including spelling, printing errors or calculation errors)
	</li>
	<li>Crashes / missing functionality of the application or virus spread through the application</li>
	<li>Information entered by third parties unjustified</li>
	<li>Information that is inserted under the abuse or violation of the Terms of Use or applicable laws</li>
	<li>Crash or impact of information on the application as a result of force majeure events, including war, civil
		unrest, natural disasters (no matter what kind), or labour disputes.
	</li>
</ul>
<p>Note particularly that the details of the calculation of the consumption is not binding. ista is not responsible for
	the information concerning the calculated consumption of the application is correct and current. The customer must
	instead rely on the bill as it is sent.</p>
<p>The above disclaimer applies to the personal liability of employees, legal representatives and vicarious for
	ista.</p>
<h2>4. Data Protection of Personal Data</h2>
<p>What personal information is held by ista and for what purpose:</p>
<ul>
	<li>If you are a resident of a property where heat financial statements are prepared in ista, ista has gathered
		information about your nme and address in order to meet this objective. The information about your name,
		address, and consumables used among other things allows you within the application to keep track of your
		consumption by entering your user name and password that you received in the welcome letter.
	</li>

	<li>Your email address if you give this to ista will be used so that ista can send you new information about login
		information.
	</li>
</ul>
<p>Ista undertakes not to disclose any information to outside third parties except as required by any regulatory
	requirements.</p>
<p>The information about your name and address are kept safe from unauthorised access, and we shall take the necessary
	measures to prevent the granting of access to the application without passwords.</p>
<p>You are responsible for maintaining the confidentiality of your personal passwords.</p>
<p>Please ensure you only give officers access to your passwords. If the password is entered incorrectly three times -
	for your own safety the site will be locked for access for a few minutes. You will then have another opportunity to
	enter your passwords.</p>
<p>If you forget your passwords, you have the option to order new codes. You must also ordered new passwords if a third
	party gets access to the application. In this case, you must immediately contact us so we immediately for safety
	reasons, can block access to the application.</p>
<p>You have the right at any time to gain access to personal information held by us in accordance with the Act on the
	protection of personal data. If you have any objections or questions related to the treatment or the security of
	your personal information, please contact:</p>
<p>Ista Energy Solutions Limited</p>
<p>3 Riverside, Granta Park</p>
<p>Great Abington</p>
<p>CB21 6AD</p>
<h2>5. Intellectual Property Rights to the Application</h2>
<p>Ista has all rights to the application form and content including copyrights to images, text, audio files, software,
	trademarks for logos and other intellectual property rights.</p>
<h2>6. Applicable Law and Jurisdiction</h2>
<p>Any dispute arising out of use of the application is subject to British law and complaints must be received entirely
	through ista energy solutions headquarters.</p>
<h2>7. Amendment of the Conditions</h2>
<p>Ista reserves the right at any time to modify, renew, or otherwise adjust these terms of use without obligation to
	inform users.</p>
<p>The use or continued use of the application after the changes take effect; then the user is stating their
	understanding and acceptance of the new conditions.</p>
