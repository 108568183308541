import { Component } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { AppErrorService } from '@errors/services/app-error.service';
import { BehaviorSubject, distinctUntilChanged, filter, map } from 'rxjs';
import { LanguageSelectionService } from '@shared/localization';
import { BrandingService } from '@shared/branding';

@Component({
  selector: 'ic-root',
  template: `
		<router-outlet *ngIf="locale$ | async"></router-outlet>
	`
})
export class AppComponent {
  public title = 'Ista connect';
  public locale$ = this.languageSelection.locale$;

  private routeParamsEmitter = new BehaviorSubject<any>(null);

  public constructor(
    private languageSelection: LanguageSelectionService,
    private router: Router,
    private branding: BrandingService,
    private appError: AppErrorService
  ) {
    this.subscribeToRouteActivation();
    this.subscribeToAppError();
    this.branding.initialize();
  }

  public get routeParams$() {
    return this.routeParamsEmitter.asObservable();
  }

  private subscribeToRouteActivation() {
    this.router.events
      .pipe(
        filter(e => e instanceof ActivationEnd),
        map((event: any) => event.snapshot),
        filter((event: any) => !!Object.keys(event?.params || {}).length)
      )
      .subscribe(({ params }: any) => this.routeParamsEmitter.next(params || {}));
  }

  private subscribeToAppError() {
    this.appError.error$
      .pipe(
        filter(error => !!error),
        distinctUntilChanged()
      )
      .subscribe({
        next: (error) => this.router.navigate(['/error'])
      });
  }
}
