import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookiePolicyPageComponent } from './cookies/components/cookie-policy-page/cookie-policy-page.component';
import { TermsComponent } from './terms/components/terms/terms.component';
import { SharedModule } from '@shared/shared.module';
import { UserFeatureTabsComponent } from './main/components/user-feature-tabs/user-feature-tabs.component';
import { FeatureTabButtonComponent } from './main/components/feature-tab-button/feature-tab-button.component';
import { WelcomeComponent } from './welcome/components/welcome/welcome.component';
import { ContentRoutingModule } from './content-routing.module';
import { SecurityModule } from '@security/security.module';
import { ImprintComponent } from './imprint/components/imprint/imprint.component';
import {
  ImprintCZComponent,
  ImprintDEComponent, ImprintITComponent,
  ImprintLocalizedComponent, ImprintPLComponent, ImprintROComponent
} from './imprint/components/imprint-localized/imprint-localized.component';
import { ContactComponent, ContactFormComponent } from './contact/components';
import { ContactSentComponent } from './contact/components/contact-sent/contact-sent.component';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbDropdownModule,
    RouterModule,
    SharedModule,
    NgxSpinnerModule,
    ContentRoutingModule,
    SecurityModule,
    ReactiveFormsModule
  ],
  declarations: [
    TermsComponent,
    CookiePolicyPageComponent,
    UserFeatureTabsComponent,
    FeatureTabButtonComponent,
    WelcomeComponent,
    ImprintComponent,
    ImprintLocalizedComponent,
    ImprintCZComponent,
    ImprintDEComponent,
    ImprintITComponent,
    ImprintPLComponent,
    ImprintROComponent,
    ContactComponent,
    ContactFormComponent,
    ContactSentComponent
  ],
  exports: [
    UserFeatureTabsComponent
  ]
})
export class ContentModule {
}
