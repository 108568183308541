import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngInclude]'
})
export class NgIncludeDirective {

  public constructor(
    public viewContainer: ViewContainerRef
  ) { }

}
