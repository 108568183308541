import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CountryDetails } from '@shared/localization';


@Component({
  selector: 'ic-country-selector',
  template: `
		<p>
			{{ 'TERMS_OF_USE.SELECT_COUNTRY' | translate }}
		</p>
		<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 padding-0">
			<div #countryDropDown="ngbDropdown" class="btn-group" ngbDropdown>
				<button id="country-select-button" type="button" class="btn btn-primary" ngbDropdownToggle>
					<span *ngIf="!selectedCountryCode">
						{{ 'TERMS_OF_USE.COUNTRY' | translate }}
					</span>
					<span *ngIf="selectedCountryCode">
						{{ 'COUNTRIES.' + selectedCountryCode | translate }}
					</span>
					<span class="caret"></span>
				</button>

				<ul class="dropdown-menu" role="menu" aria-labelledby="country-select-button" ngbDropdownMenu>
					<li *ngFor="let country of countries">
						<a href="javascript:void(0);" (click)="onSelected(country.countryCode, countryDropDown)">
							{{ 'COUNTRIES.' + country.countryCode | translate }}
						</a>
					</li>
				</ul>
			</div>
		</div>
	`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectorComponent {
  @Input()
  public countries: CountryDetails[];

  @Output()
  public selected = new EventEmitter<string>();

  public selectedCountryCode: string;

  public onSelected(country: string, target: NgbDropdown) {
    this.selectedCountryCode = country;

    target.close();
    this.selected.next(country);
  }

}
