import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomizedModalService } from '@shared/modals';
import { Notification, NotificationService } from '@shared/notifications';
import { validateEmail } from '@shared/forms/validators';
import { UserRegistrationApiService, UserRegistrationPayload } from '@security/registration';

enum FormKey {
  REGISTRATION_KEY = 'registrationKey',
  EMAILS = 'emails',
  NEW_EMAIL = 'newEmail',
  NEW_EMAIL_CONFIRMATION = 'newEmailConfirmation',
  TERMS_ACCEPTED = 'termsAccepted',
}

@Component({
  selector: 'ic-user-registration',
  templateUrl: './user-registration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRegistrationComponent implements OnInit {

  @ViewChild('termsModal', { static: true }) public termsModal: TemplateRef<any>;

  public country?: string;
  public FormKey = FormKey;
  public tracking = { accepted: true, countryCode: undefined };

  public readonly registrationKeyLength = 12;

  public form = new FormGroup({
    [FormKey.REGISTRATION_KEY]: new FormControl('', [Validators.compose([
      Validators.required,
      Validators.minLength(this.registrationKeyLength),
      Validators.maxLength(this.registrationKeyLength),
    ])]),
    [FormKey.EMAILS]: new FormGroup({
      [FormKey.NEW_EMAIL]: new FormControl('', [Validators.compose([
        Validators.required,
        Validators.minLength(8),
        validateEmail,
      ])]),
      [FormKey.NEW_EMAIL_CONFIRMATION]: new FormControl('', []),
    }, { validators: [this.validateMatchingEmail] }),
    [FormKey.TERMS_ACCEPTED]: new FormControl(false, [Validators.requiredTrue]),
  });

  private termsModalRef?: NgbModalRef;
  private readonly notificationPlaceholder = 'userRegistration';

  public constructor(
    private userRegistration: UserRegistrationApiService,
    private notifications: NotificationService,
    private modalService: CustomizedModalService,
  ) { }

  public ngOnInit(): void {
    this.resetForm();
  }

  public getNotificationPlaceholder() {
    return this.notificationPlaceholder;
  }

  public resolveUserCountry() {
    if (this.registrationKeyHasError()) {
      return;
    }

    this.userRegistration.countryCode(this.getRegistrationKey())
      .subscribe({
        next: ({ country }) => this.onResolveCountrySuccess(country),
        error: () => this.onResolveCountryError(),
      });
  }

  public registerUser() {
    this.userRegistration.userRegistration(this.getRegistrationPayload())
      .subscribe({
        next: () => this.onRegistrationSuccess(),
        error: () => this.onRegistrationError(),
      });
  }

  public openTerms($event) {
    $event.preventDefault();

    this.termsModalRef = this.modalService.open(this.termsModal);

    this.termsModalRef.shown.subscribe(() => {
      document.querySelector('ngb-modal-window')?.scrollTo(0, 0);
    });
  }

  public closeTerms() {
    this.termsModalRef?.close();
  }

  public getEmail() {
    return this.form.get(FormKey.EMAILS).get(FormKey.NEW_EMAIL).value;
  }

  public registrationKeyHasError() {
    return this.form.get(FormKey.REGISTRATION_KEY).invalid && !this.form.get(FormKey.REGISTRATION_KEY).pristine;
  }

  public emailHasError() {
    return this.form.get(FormKey.EMAILS).get(FormKey.NEW_EMAIL).invalid
      && !this.form.get(FormKey.EMAILS).get(FormKey.NEW_EMAIL).pristine;
  }

  public emailConfirmationHasError() {
    return this.form.get(FormKey.EMAILS).invalid
      && !this.form.get(FormKey.EMAILS).get(FormKey.NEW_EMAIL_CONFIRMATION).pristine;
  }

  private onResolveCountrySuccess(country: string) {
    this.country = country;
    this.tracking.countryCode = country;
    this.notifications.clear(this.getNotificationPlaceholder());
    this.form.get(FormKey.TERMS_ACCEPTED).enable();
  }

  private onResolveCountryError() {
    this.country = undefined;
    this.tracking.countryCode = undefined;

    this.notifications.show(
      Notification.inline().asError({
        messageKey: 'USER_REGISTRATION.INVALID_REGISTRATION_CODE',
        placeholderId: this.getNotificationPlaceholder(),
      }),
    );

    this.form.get(FormKey.TERMS_ACCEPTED).disable();
  }

  private onRegistrationSuccess() {
    this.notifications.show(
      Notification.toast().asSuccess({
        messageKey: 'USER_REGISTRATION.SUCCESS',
        messageParams: {
          email: this.getEmail(),
        },
      }),
    );

    this.resetForm();
  }

  private onRegistrationError() {
    this.notifications.show(
      Notification.toast().asError({
        messageKey: 'USER_REGISTRATION.ERROR_DEFAULT',
      }),
    );
  }

  private validateMatchingEmail(c: AbstractControl): { validateMatchingEmail?: boolean } {
    if (c.get(FormKey.NEW_EMAIL).value !== c.get(FormKey.NEW_EMAIL_CONFIRMATION).value) {
      return { validateMatchingEmail: true };
    }

    return {};
  }

  private getRegistrationPayload(): UserRegistrationPayload {
    return {
      newEmailAddress: this.form.get(FormKey.EMAILS).get(FormKey.NEW_EMAIL).value,
      token: this.form.get(FormKey.REGISTRATION_KEY).value,
      termsOfUseAccepted: this.form.get(FormKey.TERMS_ACCEPTED).value,
      trackingAccepted: this.tracking.accepted,
    };
  }

  private resetForm() {
    this.form.reset();
    this.form.get(FormKey.TERMS_ACCEPTED).disable();

    this.country = undefined;
    this.tracking.countryCode = undefined;
  }

  private getRegistrationKey() {
    return this.form.get(FormKey.REGISTRATION_KEY).value;
  }
}
