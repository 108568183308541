<form name="contactForm" [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate
      class="form form-horizontal form-contact">
	<div class="col-lg-6 col-md-6 col-sm-6">
		<div class="form-group contact-name" [class.has-error]="hasError(FormKeys.NAME)">
			<label for="name">
				<span>
					{{ 'CONTACT.FORM_NAME' | translate }}
				</span>
			</label>

			<input [formControlName]="FormKeys.NAME" type="text" name="name" id="name"
			       class="form-control form-contact-name"
			/>

			<p *ngIf="hasError(FormKeys.NAME)"
			   class="help-block form-contact-name-error">
				<span>
					{{ 'CONTACT.FORM_NAME_ERROR' | translate }}
				</span>
			</p>
		</div>

		<div class="form-group contact-emailAddress" [class.has-error]="hasError(FormKeys.EMAIL_ADDRESS)">
			<label for="emailAddress">
				<span>
					{{ 'CONTACT.FORM_EMAIL' | translate }}
				</span>
			</label>

			<input [formControlName]="FormKeys.EMAIL_ADDRESS" type="text"
			       name="emailAddress" id="emailAddress"
			       class="form-control form-contact-emailAddress"
			/>

			<p *ngIf="hasError(FormKeys.EMAIL_ADDRESS)"
			   class="help-block form-contact-emailAddress-error">
				<span>
					{{ 'CONTACT.FORM_EMAIL_ERROR' | translate }}
				</span>
			</p>
		</div>

		<div *ngIf="showFullForm" [class.has-error]="hasError(FormKeys.COUNTRY)"
		     class="form-group contact-country">
			<label for="country">
				<span>
					{{ 'CONTACT.FORM_COUNTRY' | translate }}
				</span>
			</label>

			<select [formControlName]="FormKeys.COUNTRY"
			        name="country" id="country"
			        class="form-control form-contact-country">
				<option *ngFor="let country of countries" [value]="country.value">
					{{ ('COUNTRIES.' + country.label) | translate }}
				</option>
			</select>

			<p *ngIf="hasError(FormKeys.COUNTRY)"
			   class="help-block form-contact-country-error">
				<span>
					{{ 'CONTACT.FORM_COUNTRY_ERROR' | translate }}
				</span>
			</p>
		</div>

		<div class="form-group contact-phone" [class.has-error]="hasError(FormKeys.PHONE_NUMBER)">
			<label for="phoneNumber">
				<span>
					{{ 'CONTACT.FORM_PHONE' | translate }}
				</span>
			</label>

			<input [formControlName]="FormKeys.PHONE_NUMBER" type="text"
			       name="phoneNumber" id="phoneNumber"
			       class="form-control form-contact-phone"
			/>

			<p *ngIf="hasError(FormKeys.PHONE_NUMBER)"
			   class="help-block form-contact-phone-error">
				<span>
					{{ 'CONTACT.FORM_PHONE_ERROR' | translate }}
				</span>
			</p>
		</div>

		<div *ngIf="showFullForm" [class.has-error]="hasError(FormKeys.PROPERTY_NUMBER)"
		     class="form-group contact-property">
			<label for="propertyNumber">
				<span>
					{{ 'CONTACT.FORM_PROPERTY_NUMBER' | translate }}
				</span>
			</label>

			<input [formControlName]="FormKeys.PROPERTY_NUMBER" type="text"
			       name="propertyNumber" id="propertyNumber"
			       class="form-control form-contact-property"
			/>

			<p *ngIf="hasError(FormKeys.PROPERTY_NUMBER)"
			   class="help-block form-contact-property-error">
				<span>
					{{ 'CONTACT.FORM_PROPERTY_NUMBER_ERROR' | translate }}
				</span>
			</p>
		</div>

		<div class="form-group contact-message" [class.has-error]="hasError(FormKeys.MESSAGE)">
			<label for="message">
				<span>
					{{ 'CONTACT.FORM_MESSAGE' | translate }}
				</span>
			</label>
			<textarea [formControlName]="FormKeys.MESSAGE"
			          name="message" id="message"
			          class="form-control form-contact-message" rows="5">
			</textarea>

			<div *ngIf="hasError(FormKeys.MESSAGE)" class="help-block form-contact-message-error">
				<div *ngIf="hasError(FormKeys.MESSAGE, ValidatorKeys.REQUIRED)">
					<span>
						{{ 'CONTACT.FORM_MESSAGE_REQUIRE_ERROR' | translate }}
					</span>
				</div>
				<div *ngIf="hasError(FormKeys.MESSAGE, ValidatorKeys.MAX_LENGTH)">
					<span>
						{{ 'CONTACT.FORM_MESSAGE_MAXLENGTH_ERROR' | translate }}
					</span>
				</div>
			</div>
		</div>

		<div class="btn-section-right">
			<button type="submit" class="btn btn-contact-submit"
			        [ngClass]="{ 'disabled-look': formGroup.invalid }">
				{{ 'CONTACT.FORM_SUBMIT' | translate }}
			</button>
			<button type="button" [routerLink]="['/welcome']"
			        class="btn btn-default btn-contact-cancel">
				{{ 'CONTACT.FORM_CANCEL' | translate }}
			</button>
		</div>
	</div>
</form>
