import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environment';
import { Settings, UpdateSettingsPayload } from './settings';


@Injectable({
  providedIn: 'root'
})
export class SettingsResource {

  public constructor(
    private http: HttpClient
  ) { }

  public load(userId: number) {
    return this.http
      .get<Settings>(`${environment.restEndpoint}/user/${userId}/setting`);
  }

  public update(userId: number, payload: UpdateSettingsPayload) {
    return this.http
      .put(
        `${environment.restEndpoint}/user/${userId}/setting`,
        this.toUpdatePayload(payload)
      );
  }

  private toUpdatePayload(payload: UpdateSettingsPayload) {
    const emailParams = {
      emailNotificationsEnabled: !!payload.emailForAlerts,
      email: payload.emailForAlerts
    };
    const phoneParams = {
      smsNotificationsEnabled: !!payload.phoneNumberForAlerts,
      phoneNumber: payload.phoneNumberForAlerts
    };

    return {
      trackMe: payload.trackMe,
      alertingEngineSettingsModel: {
        ...emailParams,
        ...phoneParams
      }
    };
  }
}
