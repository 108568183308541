import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

export interface AlertInfo {
  showRecoveryLink?: boolean;
  recoveryLink?: string;
  recoveryText?: string;
  class: string;
  msgCode: string;
  message?: string;
  translationData?: any;
}

@Component({
  selector: 'ic-alert-box',
  template: `
    <div *ngIf="!!localAlert" class="alert-box" [ngClass]="{'reserve-space': reserveSpace, 'fading-alert': fade}">
      <div class="alert {{ alertClass }}">
        <div>
          <ng-container *ngIf="!!localAlert.message">
            {{ localAlert.message }}
          </ng-container>
          <ng-container *ngIf="!localAlert.message">
            {{ localAlert.msgCode | translate : localAlert.translationData }}
          </ng-container>
        </div>
        <a *ngIf="localAlert.showRecoveryLink" [routerLink]="[ localAlert.recoveryLink ]"
           id="test-loginBtn" class="btn btn-primary recovery-link">
          {{ localAlert.recoveryText | translate }}
        </a>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertBoxComponent {
  @Input()
  public reserveSpace = false;
  @Input()
  public fade = false;

  public alertClass: string;
  public localAlert: AlertInfo;

  private alertInfoData: AlertInfo;

  public constructor(
    private changeDetector: ChangeDetectorRef
  ) {
  }

  @Input()
  public get alertInfo() {
    return this.alertInfoData;
  }

  public set alertInfo(info: AlertInfo) {
    this.alertInfoData = info;
    this.setupAlert(info);
  }


  private setupAlert(alertInfo: AlertInfo) {
    if (!alertInfo) {
      this.localAlert = null;
      return;
    }

    if (alertInfo.showRecoveryLink) {
      if (!alertInfo.recoveryLink) { throw new Error('No recoveryLink is provided!'); }
      if (!alertInfo.recoveryText) { throw new Error('No recoveryText is provided!'); }
    }

    this.localAlert = Object.assign({}, alertInfo);
    this.alertClass = `alert-${this.localAlert.class}`;

    this.changeDetector.markForCheck();
  }

}
