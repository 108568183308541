import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environment';


export interface UserActivationPayload {
  newPassword: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserActivationApiService {

  public constructor(
    private http: HttpClient
  ) { }

  public userActivation(payload: UserActivationPayload) {
    return this.http.post(
      `${environment.restEndpoint}/userActivation`,
      payload
    );
  }
}
