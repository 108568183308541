<div *ngIf="!accepted" class="cookies">
	<div class="alert-warning col-xs-12 col-sm-12 col-md-12 col-lg-12" role="alert">
		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
				<ng-container ngInclude></ng-container>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
				<a [routerLink]="['/cookie-policy']">
					{{ 'COOKIE_POLICY.SHOW_BUTTON' | translate }}
				</a>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-right">
				<button type="button" class="btn btn-primary btn-login-details-submit"
                (click)="acceptCookiePolicy()">
					<div>{{ 'ACCEPT' | translate }}</div>
				</button>
			</div>
		</div>
	</div>
</div>
