import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit, ViewChild
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { map, Observable, switchMap } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { LanguageSelectionService } from '@shared/localization';
import { icBrand } from '@shared/branding';
import { RouteDataService } from '@shared/routing';
import { CountryFeatureService, CurrentUserService, RoleManagerService } from '@security/user';

@Component({
  selector: 'ic-top-navigation',
  templateUrl: './top-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavigationComponent implements OnInit, OnDestroy {
  @ViewChild('hamburgerIcon', { static: true })
  public hamburgerIcon: ElementRef<HTMLImageElement>;

  public allowedLocaleStrings = this.languageSelection.allowedLocaleStrings;
  public isBranded = !!this.brandName;
  public brandLogo = this.isBranded
    ? `/s2/brands/${this.brandName}/logo_1.png`
    : '/assets/images/ista_logo_inv.svg';
  public assetsRev = +new Date();

  public locale$ = this.languageSelection.locale$;
  public currentUser$ = this.currentUser.user$;
  public isChangePasswordEnabled$: Observable<boolean>;
  public isChangeEmailEnabled$: Observable<boolean>;
  public showUserDetails$ = this.currentUser$.pipe(
    map(user => this.roleManager.isUser(user))
  );
  public sideDrawerActive = false;

  public constructor(
    private languageSelection: LanguageSelectionService,
    private roleManager: RoleManagerService,
    private countryFeature: CountryFeatureService,
    protected currentUser: CurrentUserService,
    private changeDetector: ChangeDetectorRef,
    private element: ElementRef,
    @Inject(icBrand) private brandName: string,
    @Inject(DOCUMENT) private document: Document,
    private routeData: RouteDataService
  ) {
    this.subscribeToCountryFeatures();
  }

  public get data$() {
    return this.routeData.data$;
  }

  public get wrapperElement() {
    return (this.element.nativeElement as HTMLElement).querySelector('.hamburger-foldout');
  }

  public ngOnInit() {
    this.registerClickOutsideHandler();
  }

  public ngOnDestroy() {
    this.deregisterClickOutsideHandler();
  }

  public setActivationState() {
    this.sideDrawerActive = !this.sideDrawerActive;
    this.changeDetector.markForCheck();
  }

  public async onChangeLanguage(locale: string, target: NgbDropdown) {
    await this.languageSelection.setLocale(locale);
    target.close();
  }

  private subscribeToCountryFeatures() {
    this.isChangePasswordEnabled$ = this.currentUser.ensureUser$.pipe(
      switchMap(() => this.countryFeature.isChangePasswordEnabled())
    );
    this.isChangeEmailEnabled$ = this.currentUser.ensureUser$.pipe(
      switchMap(() => this.countryFeature.isChangeEmailEnabled())
    );
  }

  private registerClickOutsideHandler() {
    this.document.addEventListener('click', this.handleClickOutside.bind(this));
  }

  private deregisterClickOutsideHandler() {
    this.document.removeEventListener('click', this.handleClickOutside);
  }

  private handleClickOutside(event: PointerEvent) {
    if (!this.sideDrawerActive) { return; }

    const clickedOnMenuButton = this.hamburgerIcon.nativeElement === event.target;
    const clickedOutside = !this.wrapperElement.contains(event.target as HTMLElement);

    if (clickedOnMenuButton || !clickedOutside) { return; }

    this.sideDrawerActive = false;
    this.changeDetector.markForCheck();
  }
}
