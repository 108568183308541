import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

interface CountryCodeResponse {
  country: string;
}

export interface UserRegistrationPayload {
  newEmailAddress: string;
  token: string;
  termsOfUseAccepted: boolean;
  trackingAccepted: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationApiService {

  public constructor(
    private http: HttpClient
  ) { }

  public countryCode(registrationCode: string) {
    return this.http.get<CountryCodeResponse>(
      `${environment.restEndpoint}/userRegistration/country/${registrationCode}`,
    );
  }

  public userRegistration(payload: UserRegistrationPayload) {
    return this.http.post<void>(
      `${environment.restEndpoint}/userRegistration`,
      payload,
    );
  }
}
