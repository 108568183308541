<ic-default-layout>
	<div class="welcome col-lg-8 col-md-8 col-sm-6">
		<h1 class="page-header">{{ 'USER_REGISTRATION.TITLE' | translate }}</h1>

		<h2>{{ 'USER_REGISTRATION.WELCOME' | translate }}</h2>
		<span [innerHTML]="'USER_REGISTRATION.TEXT' | translate"></span>

		<h2></h2>
		<ic-inline-notification [identifier]="getNotificationPlaceholder()"></ic-inline-notification>

		<form
			name="userRegistrationForm"
			class="form form-horizontal register-form"
			[formGroup]="form"
			(submit)="registerUser()"
			novalidate
		>
			<div class="form-group form-group-registration-code" [ngClass]="{ 'has-error': registrationKeyHasError() }">
				<div class="col-lg-8 col-md-8 col-sm-10">
					<label [for]="FormKey.REGISTRATION_KEY">{{ 'USER_REGISTRATION.KEY_LABEL' | translate }}</label>
					<input
						class="form-control"
						type="text"
						[name]="FormKey.REGISTRATION_KEY"
						[id]="FormKey.REGISTRATION_KEY"
						[formControlName]="FormKey.REGISTRATION_KEY"
						autocomplete="off"
						(change)="resolveUserCountry()"
					/>
				</div>

				<div class="col-md-12">
					<p class="help-block" *ngIf="registrationKeyHasError()">
						{{ 'USER_REGISTRATION.ERROR_KEY_REQUIREMENTS' | translate }}
					</p>
				</div>
			</div>

			<ng-container [formGroupName]="FormKey.EMAILS">
				<div class="form-group form-group-new-email" [ngClass]="{ 'has-error' : emailHasError() }">
					<div class="col-lg-8 col-md-8 col-sm-10">
						<label [for]="FormKey.NEW_EMAIL">{{ 'USER_REGISTRATION.EMAIL_LABEL' | translate }}</label>
						<input
							class="form-control"
							type="email"
							[name]="FormKey.NEW_EMAIL"
							[id]="FormKey.NEW_EMAIL"
							[formControlName]="FormKey.NEW_EMAIL"
							autocomplete="off"
						/>
					</div>
					<div class="col-md-12">
						<p class="help-block" *ngIf="emailHasError()">
							{{ 'USER_REGISTRATION.ERROR_EMAIL_REQUIREMENTS' | translate}}
						</p>
					</div>
				</div>

				<div
					class="form-group form-group-new-email-confirmation"
					[ngClass]="{ 'has-error': emailConfirmationHasError() }"
				>
					<div class="col-lg-8 col-md-8 col-sm-10">
						<label [for]="FormKey.NEW_EMAIL_CONFIRMATION">
							{{ 'USER_REGISTRATION.EMAIL_CONFIRMATION_LABEL' | translate }}
						</label>
						<input
							class="form-control"
							type="email"
							[name]="FormKey.NEW_EMAIL_CONFIRMATION"
							[id]="FormKey.NEW_EMAIL_CONFIRMATION"
							[formControlName]="FormKey.NEW_EMAIL_CONFIRMATION"
							autocomplete="off"
						/>
					</div>
					<div class="col-md-12">
						<p class="help-block" *ngIf="emailConfirmationHasError()">
							{{ 'USER_REGISTRATION.ERROR_EMAIL_CONFIRMATION' | translate}}
						</p>
					</div>
				</div>
			</ng-container>

			<div class="form-group">
				<div class="col-lg-8 col-md-8 col-sm-10">
					<label class="checkbox">
						<input type="checkbox" [formControlName]="FormKey.TERMS_ACCEPTED">
						<span [attr.disabled]="!country || null">
							{{ 'USER_REGISTRATION.TERMS_OF_USE_CONFIRMATION_START' | translate }}&#32;
							<a class="btn btn-link" (click)="country && openTerms($event)" [attr.disabled]="!country || null">
								{{ 'USER_REGISTRATION.TERMS_OF_USE' | translate }}
							</a>&#32;
							{{ 'USER_REGISTRATION.TERMS_OF_USE_CONFIRMATION_END' | translate }}
						</span>
						<i
							class="fa fa-info-circle"
							[ngbTooltip]="'USER_REGISTRATION.FILL_REGISTRATION_CODE' | translate"
							placement="bottom"
						></i>
					</label>
				</div>
			</div>

			<button
				type="submit"
				class="btn btn-primary btn-register-user-submit"
				[disabled]="form.invalid || !form.get(FormKey.TERMS_ACCEPTED).value || !country"
			>
				<i class="fa fa-check"></i>
				{{ 'USER_REGISTRATION.BUTTON' | translate }}
			</button>
		</form>
	</div>
</ic-default-layout>

<ng-template #termsModal>
	<div class="terms-modal">
		<div class="modal-header">
			<div class="col-xs-10">
				<h3 class="modal-title" id="modal-title">{{ 'TERMS_OF_USE.TITLE' | translate }}</h3>
			</div>
			<div class="col-xs-2">
				<a (click)="closeTerms()"><i class="fa fa-times"></i></a>
			</div>
		</div>
		<div class="modal-body" id="modal-body">
			<ic-term [countryCode]="country"></ic-term>
			<ic-check-box [countryCode]="country" [terms]="tracking"></ic-check-box>
		</div>
		<div class="modal-footer">
			<button class="btn btn-primary" type="button" (click)="closeTerms()">
				{{ 'TERMS_OF_USE.CLOSE' | translate }}
			</button>
		</div>
	</div>
</ng-template>
