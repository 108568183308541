<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate autocomplete="off"
	  class="form form-horizontal">
	<div class="form-group form-group-current-password"
		 [ngClass]="{ 'has-error': controlHasError(FormKeys.CURRENT_PASSWORD) }">
		<div class="col-lg-3 col-md-3 col-sm-4">
			<label for="currentPassword">{{ 'CURRENT_PASSWORD_LABEL' | translate }}</label>
			<input [formControlName]="FormKeys.CURRENT_PASSWORD" name="currentPassword" id="currentPassword"
				   class="form-control" autofocus autocomplete="off" type="password" />
		</div>
		<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
			<p *ngIf="controlHasError(FormKeys.CURRENT_PASSWORD)" class="help-block">
				{{ 'ERROR_PASSWORD_REQUIRED' | translate }}
			</p>
		</div>
	</div>

	<ic-new-password-fields-section
		[controlHasErrorFn]="controlHasError.bind(this)">
	</ic-new-password-fields-section>

	<button type="submit" class="btn btn-primary btn-change-password-submit"
			[disabled]="formGroup.invalid">
		<i class="fa fa-check"></i>
		{{"CHANGE_PASSWORD" | translate}}
	</button>
</form>
