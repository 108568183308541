import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';


export enum FormKeys {
  NEW_PASSWORD = 'newPassword',
  NEW_PASSWORD_CONFIRMATION = 'newPasswordConfirmation'
}

export enum ValidationKeys {
  REQUIRED = 'required',
  STRONG_PASSWORD = 'strongpassword'
}

@Component({
  selector: 'ic-new-password-fields-section',
  templateUrl: './new-password-fields-section.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  styles: [`
    label .fa {
      cursor: pointer;
      color: var(--app-red-85);
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewPasswordFieldsSectionComponent implements OnInit, OnDestroy {
  @Input()
  public controlHasErrorFn: (controlName: FormKeys, validatorName?: string) => boolean;

  @Output()
  public changed = new EventEmitter<void>();

  public FormKeys = FormKeys;
  public ValidationKeys = ValidationKeys;

  public constructor(
    public formGroup: FormGroupDirective
  ) { }

  public ngOnInit() {
    this.formGroup.form.addValidators([this.newPasswordsMatch]);
  }

  public ngOnDestroy() {
    this.formGroup.form.removeValidators([this.newPasswordsMatch]);
  }

  public formGroupHasError(validatorName?: string) {
    return !!this.formGroup.errors && (!validatorName || !!this.formGroup.errors[validatorName]);
  }

  public controlHasError(controlName: FormKeys, validatorName?: string) {
    if (!this.controlHasErrorFn) { return false; }

    return this.controlHasErrorFn(controlName, validatorName);
  }

  private newPasswordsMatch(group: FormGroup) {
    const { newPassword, newPasswordConfirmation } = group.getRawValue();

    if (!newPassword || !newPasswordConfirmation) { return null; }

    return newPassword === newPasswordConfirmation
      ? null
      : { newPasswordsMatch: true };
  }
}
