import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandlerParams } from '@ngx-translate/core/lib/missing-translation-handler';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorsModule } from './errors/errors.module';
import { SharedModule } from './shared/shared.module';
import { SecurityModule } from './security/security.module';
import { TranslationLoadingService } from './shared/localization';
import { ContentModule } from './content/content.module';
import { UserSecurityModule } from '@user/security/user-security.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '@environment';


const initializeKeycloak = (keycloak: KeycloakService) => () =>
  keycloak.init({
    config: environment.keycloak,
    initOptions: {
      checkLoginIframe: true,
      checkLoginIframeInterval: 25,
    },
    loadUserProfileAtStartUp: true,
    bearerExcludedUrls: [
      // NOTE: this endpoint intentionally sets the 'Authorization' header to null.
      // Such behavior would be overwritten by Keycloak interceptor if URL was not excluded
      '/countries'
    ]
  });

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    KeycloakAngularModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-gb',
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoadingService
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useValue: { handle: (params: MissingTranslationHandlerParams) => params.key } // TODO
      }
    }),
    NgbModule,
    AngularSvgIconModule.forRoot(),
    NgxSpinnerModule.forRoot(),
    ToastrModule.forRoot(),
    CookieModule.withOptions(),
    SharedModule.forRoot(),
    SecurityModule.forRoot(),
    ErrorsModule,
    ContentModule,
    AppRoutingModule,
    UserSecurityModule,
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}
