import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslationPrefix } from '@shared/localization';

@Component({
  selector: 'ic-mfa-instructions',
  templateUrl: './mfa-instructions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfaInstructionsComponent {
  public constructor(
    protected translationPrefix: TranslationPrefix
  ) {
  }
}
