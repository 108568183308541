import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DenyAnonymousGuard, requireRole, ensureCurrentUser, UserRole } from '@security/user';
import { ContactComponent } from './contact/components';
import { CookiePolicyPageComponent } from './cookies/components/cookie-policy-page/cookie-policy-page.component';
import { ImprintComponent } from './imprint';
import { TermsComponent } from './terms';
import { WelcomeComponent } from './welcome';

const routes: Routes = [
  // @todo move out
  {
    path: 'welcome',
    canActivate: [DenyAnonymousGuard, ensureCurrentUser(), requireRole(UserRole.PM)],
    component: WelcomeComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'imprint',
    component: ImprintComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ContentRoutingModule { }
