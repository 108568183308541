import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LabelValuePair } from '@shared/common';
import { validateEmail } from '@shared/forms/validators';
import { ContactFormInitialValue, SendContactFormPayload } from '../../models';


enum FormKeys {
  NAME = 'name',
  EMAIL_ADDRESS = 'emailAddress',
  COUNTRY = 'country',
  PHONE_NUMBER = 'phoneNumber',
  PROPERTY_NUMBER = 'propertyNumber',
  MESSAGE = 'message'
}

enum ValidatorKeys {
  REQUIRED = 'required',
  MAX_LENGTH = 'maxlength'
}

interface FormValue {
  [FormKeys.NAME]: string;
  [FormKeys.EMAIL_ADDRESS]: string;
  [FormKeys.COUNTRY]: string;
  [FormKeys.PHONE_NUMBER]: string;
  [FormKeys.PROPERTY_NUMBER]: string;
  [FormKeys.MESSAGE]: string;
}

@Component({
  selector: 'ic-contact-form',
  templateUrl: './contact-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactFormComponent {
  @Input()
  public countries: LabelValuePair[];

  @Output()
  public saving = new EventEmitter<SendContactFormPayload>();

  public formGroup: FormGroup;
  public submitted: boolean;
  public userUuid: string;

  public FormKeys = FormKeys;
  public ValidatorKeys = ValidatorKeys;

  public constructor(
    private formBuilder: FormBuilder
  ) {
    this.createForm();
    this.refreshValidityAndAvailability();
  }

  public get showFullForm() {
    return this.userUuid != null && !this.userUuid;
  }

  @Input()
  public set initialValue(value: ContactFormInitialValue) {
    if (!value) { return; }

    this.userUuid = value.userUuid;
    this.formGroup.patchValue(this.toFormValue(value));
    this.refreshValidityAndAvailability();
  }

  public onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) { return; }

    const formValue = this.formGroup.getRawValue() as FormValue;

    this.saving.next(
      this.toPayload(formValue)
    );
  }

  public hasError(controlName: FormKeys, validatorName?: ValidatorKeys) {
    const formControl = this.formGroup.get(controlName);
    const isInvalid = !!formControl.errors
      && (!validatorName || !!formControl.errors[validatorName.toLowerCase()]);

    return formControl.invalid && isInvalid && (!formControl.pristine || this.submitted);
  }

  private createForm() {
    this.formGroup = this.formBuilder.group({
      [FormKeys.NAME]: [''],
      [FormKeys.EMAIL_ADDRESS]: [''],
      [FormKeys.COUNTRY]: [''],
      [FormKeys.PHONE_NUMBER]: [''],
      [FormKeys.PROPERTY_NUMBER]: [''],
      [FormKeys.MESSAGE]: ['']
    });
  }

  private refreshValidityAndAvailability() {
    const { validity, availability } =
      this.getControlsValidityAndAvailability(this.showFullForm);

    this.getAllFormControls().forEach(({ control, key }) => {
      control.clearValidators();
      control.setValidators(validity[key]);

      if (availability[key]) { control.enable(); }
      else { control.disable(); }
    });
  }

  private getAllFormControls() {
    return Object.values(FormKeys).map(
      key => ({ control: this.formGroup.get(key), key })
    );
  }

  private getControlsValidityAndAvailability(showFullForm: boolean) {
    const validity = {
      [FormKeys.NAME]: [Validators.required],
      [FormKeys.EMAIL_ADDRESS]: showFullForm
        ? [Validators.required, validateEmail()]
        : [],
      [FormKeys.COUNTRY]: showFullForm
        ? [Validators.required]
        : [],
      [FormKeys.PHONE_NUMBER]: [],
      [FormKeys.PROPERTY_NUMBER]: [],
      [FormKeys.MESSAGE]: [Validators.required, Validators.maxLength(5000)]
    };

    const availability = {
      [FormKeys.NAME]: true,
      [FormKeys.EMAIL_ADDRESS]: showFullForm,
      [FormKeys.COUNTRY]: true,
      [FormKeys.PHONE_NUMBER]: true,
      [FormKeys.PROPERTY_NUMBER]: true,
      [FormKeys.MESSAGE]: true
    };

    return { validity, availability };
  }

  private toPayload(value: FormValue): SendContactFormPayload {
    return {
      userUuid: this.userUuid,
      name: value[FormKeys.NAME],
      emailAddress: value[FormKeys.EMAIL_ADDRESS],
      country: value[FormKeys.COUNTRY],
      phoneNumber: value[FormKeys.PHONE_NUMBER],
      propertyNumber: value[FormKeys.PROPERTY_NUMBER],
      message: value[FormKeys.MESSAGE],
    };
  }

  private toFormValue(initialValue: ContactFormInitialValue): Partial<FormValue> {
    return {
      [FormKeys.NAME]: initialValue.name,
      [FormKeys.EMAIL_ADDRESS]: initialValue.emailAddress
    };
  }
}
