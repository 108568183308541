import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject, Observable, shareReplay, switchMap } from 'rxjs';
import { CredentialRepresentation } from '../models/credential-representation.model';
import { MfaApiService } from './mfa-api.service';
import { MfaCredentialType } from '../models/mfa-credential-type.model';

@Injectable({
  providedIn: 'root'
})
export class MfaService {
  public availableCredentials$: Observable<CredentialRepresentation[]>;

  private credentialBus = new BehaviorSubject<void>(undefined);

  public constructor(
    private resource: MfaApiService
  ) {
    this.availableCredentials$ = this.subscribeToCredentials();
  }

  public refresh() {
    this.credentialBus.next();
  }

  public removeCredential(credentialId: string) {
    return this.resource.removeCredentialById(credentialId);
  }

  public disableMfa() {
    return this.resource.removeCredentialsByType(MfaCredentialType.OTP);
  }

  public getOtpSetupUrl(redirectUrl: string) {
    return `${environment.keycloak.url}/realms/${environment.keycloak.realm}/protocol/openid-connect/auth` +
      `?response_type=code` +
      `&client_id=${environment.keycloak.clientId}` +
      `&scope=openid` +
      `&redirect_uri=${redirectUrl}` +
      `&kc_action=CONFIGURE_TOTP_MAIL`;
  }

  private subscribeToCredentials() {
    return this.credentialBus.pipe(
      switchMap(() => this.resource.getAvailableCredentials()),
      shareReplay(1)
    );
  }
}
