<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate autocomplete="off"
	  class="form form-horizontal">
	<div class="form-group">
		<div class="col-md-3 col-sm-4">
			<div class="row form-group-new-email" [ngClass]="{ 'has-error': controlHasError(FormKeys.NEW_EMAIL) }">
				<div class="col-xs-12">
					<label for="newEmail">
						{{ buildKey('CHANGE_EMAIL_NEW_EMAIL') | translate }}
					</label>

					<input type="email" [formControlName]="FormKeys.NEW_EMAIL"
						   name="newEmail" id="newEmail" class="form-control" autofocus
						   [placeholder]="buildKey('EMAIL') | translate" autocomplete="off" />
				</div>
				<span class="col-xs-12">
                    <span class="help-block" *ngIf="controlHasError(FormKeys.NEW_EMAIL, 'required')">
						{{ buildKey('ERROR_REQUIRED') | translate }}
                    </span>
                    <span class="help-block" *ngIf="controlHasError(FormKeys.NEW_EMAIL, 'pattern')">
						{{ buildKey('ERROR_EMAIL') | translate }}
                    </span>
                </span>
			</div>
		</div>
		<div class="col-md-9 col-sm-8" *ngIf="emailNotificationsAvailable">
			<div class="row form-group-update-alerting-email">
				<label class="hidden-xs">&nbsp;</label>

				<div class="checkbox col-xs-12">
					<label>
						<input type="checkbox" [formControlName]="FormKeys.UPDATE_ALERTING_EMAIL">
						{{ buildKey('UPDATE_EMAIL.ALSO_FOR_ALERTS') | translate }}
					</label>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group form-group-current-password">
		<div class="col-md-3 col-sm-4">
			<label for="password">
				{{ buildKey('PASSWORD') | translate }}
			</label>

			<input type="password" [formControlName]="FormKeys.PASSWORD"
				   class="form-control" name="password" id="password"
				   [placeholder]="buildKey('PASSWORD') | translate" />
		</div>
	</div>

	<button type="submit" class="btn btn-primary btn-change-email-submit" [disabled]="formGroup.invalid">
		<i class="fa fa-check"></i>
		{{ buildKey('CHANGE_EMAIL') | translate}}
	</button>
</form>
