<ul id="top-nav" class="top-navigation-mobile visible-xs visible-sm">
	<li class="headline">
		<a class="navbar-brand" [class.hide]="!!(data$ | async)?.backLinkTarget" [routerLink]="['/welcome']">
			<img [src]="brandLogo + '?rev=' + assetsRev" [fallbackSrc]="'/assets/images/ista_logo_inv.svg?rev=' + assetsRev" alt="Logo">
		</a>

		<span *ngIf="!isBranded"
			  [class.hide]="!!(data$ | async)?.backLinkTarget"
			  class="app-title long">
			{{ 'APP_TITLE' | translate }}
        </span>
		<span *ngIf="!isBranded"
			  [class.hide]="!!(data$ | async)?.backLinkTarget"
			  class="app-title short">
			{{ 'APP_TITLE_SHORT' | translate }}
        </span>
		<span [class.hide]="!(data$ | async)?.backLinkTarget">
            <a class="btn-go-back" [routerLink]="[ (data$ | async)?.backLinkTarget ]">
                <img src="assets/images/icons/arrow_left.svg"/>
            </a>
        </span>
	</li>

	<li class="hamburger inactive pull-right" [ngClass]="{ 'active': sideDrawerActive }">
		<a class="hamburger-button" href="javascript:void(0);" (click)="setActivationState()">
			<img #hamburgerIcon src="assets/images/navigation_back.svg" />
		</a>
		<ul class="hamburger-foldout" [ngSwitch]="currentUser">
			<li *ngSwitchCase="undefined" class="goto-login"
				[routerLink]="['/']" (click)="setActivationState()">
				<i class="fa fa-home fa-fw burger-button"></i>
				<span>{{ 'HOME' | translate }}</span>
			</li>

			<ng-container *ngSwitchDefault>
				<li class="goto-home" [routerLink]="['/welcome']" (click)="setActivationState()">
					<i class="fa fa-home fa-fw burger-button"></i>
					<span>{{ 'HOME' | translate }}</span>
				</li>
				<li *ngIf="isChangePasswordEnabled$ | async" class="goto-password"
					[routerLink]="['/password']" (click)="setActivationState()">
					<i class="fa fa-lock fa-fw burger-button"></i>
					<span>{{ 'CHANGE_PASSWORD' | translate }}</span>
				</li>
				<li *ngIf="isChangeEmailEnabled$ | async" class="goto-email"
					[routerLink]="['/email']" (click)="setActivationState()">
					<i class="fa fa-envelope fa-fw burger-button"></i>
					<span>{{ 'CHANGE_EMAIL' | translate }}</span>
				</li>
				<li class="goto-settings" [routerLink]="['/user/settings']" (click)="setActivationState()">
					<i class="fa fa-cog fa-fw burger-button"></i>
					<span>{{ 'SETTINGS' | translate }}</span>
				</li>
				<li class="goto-settings" [routerLink]="['/user/settings/mfa']" (click)="setActivationState()">
					<i class="fa fa-shield fa-fw burger-button"></i>
					<span>{{ 'SETTINGS_MFA.MENU_TITLE' | translate }}</span>
				</li>
				<li class="goto-logout" [routerLink]="['/logout']" (click)="setActivationState()">
					<i class="fa fa-sign-out fa-fw burger-button"></i>
					<span>{{ 'LOGOUT' | translate }}</span>
				</li>
			</ng-container>
		</ul>
	</li>

	<li class="dropdown mobile-locale-dropdown pull-right" ngbDropdown #mobileDropDown="ngbDropdown">
		<a href="javascript:void(0);" class="dropdown-toggle" ngbDropdownToggle>
			<div class="flag-icon flag-icon-active glossy flag-{{ (locale$ | async).localeString }}"></div>
			<span class="caret"></span>
		</a>
		<ul class="dropdown-menu locale-dropdown-menu" role="menu" ngbDropdownMenu>
			<li *ngFor="let localeString of allowedLocaleStrings"
				[class.hide]="(locale$ | async).localeString === localeString">
				<a class="mobile-lang-{{localeString}}" (click)="onChangeLanguage(localeString, mobileDropDown)">
					<div class="flag-icon glossy flag-{{localeString}}"></div>
					{{"LANG_" + localeString.toUpperCase() | translate}}
				</a>
			</li>
		</ul>
	</li>
</ul>

<div id="top-nav-desktop" class="hidden-xs hidden-sm">
	<nav class="navbar navbar-default top-navigation-desktop" role="navigation">
		<div class="container-fluid">
			<div class="navbar-header">
				<a class="navbar-brand" [routerLink]="['/']">
					<img src="{{ brandLogo + '?rev=' + assetsRev }}" alt="Logo">
				</a>
			</div>

			<ul class="nav navbar-nav" *ngIf="!isBranded">
				<li>
					<a [routerLink]="['/welcome']" class="top-nav-title">
						{{ 'APP_TITLE' | translate }}
					</a>
				</li>
			</ul>

			<ul class="nav navbar-nav navbar-right">
				<li class="dropdown locale-dropdown" ngbDropdown #dropDown="ngbDropdown">

					<a href="javascript:void(0);" id="test-langDropdown"
					   class="dropdown-toggle" ngbDropdownToggle>
						<div class="flag-icon flag-icon-active glossy flag-{{ (locale$ | async).localeString }}"></div>
						<span class="caret"></span>
					</a>

					<ul class="dropdown-menu locale-dropdown-menu" role="menu" ngbDropdownMenu>
						<li *ngFor="let localeString of allowedLocaleStrings"
							[class.hide]="(locale$ | async).localeString === localeString">
							<a class="lang-{{ localeString }}" (click)="onChangeLanguage(localeString, dropDown)">
								<div class="flag-icon glossy flag-{{ localeString }}"></div>
								{{ 'LANG_' + localeString.toUpperCase() | translate }}
							</a>
						</li>
					</ul>

				</li>
				<li *ngIf="showUserDetails$ | async" ngbDropdown class="dropdown current-user-dropdown">
					<a *ngIf="currentUser$ | async as currentUser"
						href="javascript:void(0);" id="test-userMenuDropdown"
					   	class="dropdown-toggle" ngbDropdownToggle>
						{{ currentUser.firstName }} {{ currentUser.lastName }}
						<span class="caret"></span>
					</a>
					<ul class="dropdown-menu current-user-dropdown-menu" role="menu" ngbDropdownMenu>
						<li>
							<a [routerLink]="['/welcome']" class="welcome">
								<i class="fa fa-fw fa-home"></i>
								{{ 'HOME' | translate }}
							</a>
						</li>
						<!--li><a ui-sref="help" class="help"><i class="fa fa-fw fa-question"></i>
							{{'HELP' | translate}}</a></li>-->
						<li *ngIf="isChangePasswordEnabled$ | async">
							<a [routerLink]="['/password']" class="password">
								<i class="fa fa-fw fa-lock"></i>
								{{ 'CHANGE_PASSWORD' | translate }}
							</a>
						</li>
						<li *ngIf="isChangeEmailEnabled$ | async">
							<a [routerLink]="['/email']" class="email">
								<i class="fa fa-fw fa-envelope"></i>
								{{ 'CHANGE_EMAIL' | translate }}
							</a>
						</li>
						<li>
							<a [routerLink]="['/user/settings']" class="settings">
								<i class="fa fa-fw fa-cog"></i>
								{{ 'SETTINGS' | translate }}
							</a>
						</li>
						<li>
							<a [routerLink]="['/user/settings/mfa']" class="settings">
								<i class="fa fa-fw fa-shield"></i>
								{{ 'SETTINGS_MFA.MENU_TITLE' | translate }}
							</a>
						</li>
						<li>
							<a [routerLink]="['/logout']" class="logout">
								<i class="fa fa-fw fa-sign-out"></i>
								{{ 'LOGOUT' | translate }}
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</nav>
</div>

<!-- Flag images prefetch (for slow mobile connections) -->
<div class="outside-viewport">
	<li *ngFor="let localeString of allowedLocaleStrings">
		<img src="assets/images/icons/flags/{{localeString}}.svg">
	</li>
</div>
