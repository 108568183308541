<header>
	<ic-top-navigation></ic-top-navigation>
</header>
<div class="main">
	<div class="flex-alert">
	</div>
	<div class="flex-content">
		<div class="container-fluid main-content cookies-notification-padding">
			<ng-content select="h1.page-header"></ng-content>

			<ic-inline-notification></ic-inline-notification>

			<ng-content></ng-content>
		</div>
	</div>
	<div class="flex-cookies flex-sticky-cookies">
		<ic-accept-cookies class="accept-cookies" language="{{ (locale$ | async)?.lang }}">
		</ic-accept-cookies>
	</div>
</div>
<footer>
	<ic-app-footer [isBranded]="isBranded"></ic-app-footer>
</footer>

<ic-spinner></ic-spinner>
<ic-toasts></ic-toasts>
