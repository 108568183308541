import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LanguageSelectionService } from '@shared/localization';
import { distinctUntilChanged, map, Observable, take } from 'rxjs';

@Component({
  selector: 'ic-check-box',
  template: `
		<p class="top30">
			<span>{{ desc$ | async }}</span>
		</p>

		<div class="checkbox">
			<label>
				<input type="checkbox" (change)="switch()" [(ngModel)]="terms.accepted" />
				<span>{{ label$ | async }}</span>
			</label>
		</div>
    `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckBoxComponent {
  @Input()
  public terms: { accepted: boolean; countryCode: string }; // TODO: duplicated inputs

  public translations$: Observable<any>;

  public constructor(
    private languageSelection: LanguageSelectionService
  ) { }

  public get label$() {
    return this.localize('LABEL', this.terms?.accepted);
  }

  public get desc$() {
    return this.localize('DESCRIPTION', this.terms?.accepted);
  }

  @Input()
  public set countryCode(code: string) {
    // TODO null reference
    const languageCode = `${code.toLowerCase()}-${code.toLowerCase()}`;

    // TranslateService.getTranslation implicitly switches the context language (bug?). To bypass this,
    // getTranslationWithoutSwitchingLanguage does exactly the same but without manipulating the current
    // translation context
    this.translations$ = this.languageSelection.getTranslationWithoutSwitchingLanguage(languageCode).pipe(
      distinctUntilChanged(),
      take(1)
    );
  }

  public switch() {

  }

  private localize(key: string, accepted: boolean) {
    const acceptanceKey = accepted ? 'ACCEPTED' : 'REFUSED';

    return this.translations$
      .pipe(
        map((json) => json[`TERMS_OF_USE_TRACKING.${acceptanceKey}.${key}`]),
      );
  }
}
