<br>
<div class="form form-horizontal form-contact-sent">
	<div class="form-group contact-has-been-sent">
		<div class="col-lg-6 col-md-6 col-sm-6">
			<label>
				<span>{{ 'CONTACT.CONTACT_HAS_BEEN_SENT' | translate }}</span>
			</label>
		</div>
	</div>
	<button type="button" [routerLink]="['/welcome']"
	        class="btn btn-primary btn-contact-has-been-sent">
		{{ 'OK' | translate }}
	</button>
</div>
