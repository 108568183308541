import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CurrentUserService } from '@security/user';


@Component({
  selector: 'ic-logout',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent implements OnInit {
  public constructor(
    private currentUser: CurrentUserService,
  ) { }

  public ngOnInit() {
    this.currentUser.logout();
  }

}
