<h2>Polityka plików Cookies</h2>
<p>Niniejsza polityka plików Cookies określa zasady przechowywania i dostępu do informacji na urządzeniach Użytkownika
	za pomocą plików Cookies, służących realizacji żądanych przez Użytkownika usług świadczonych drogą elektroniczną
	przez ista Polska sp. z o.o.</p>
<p class="align-center"><strong>§ 1. Definicje</strong></p>
<p>1. <strong>Administrator</strong> – oznacza ista Polska sp. z o.o. z siedzibą w Krakowie, al. 29 Listopada 155c
	wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Krakowa –
	Śródmieścia w Krakowie, Wydział XI Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000010442, NIP:
	525-10-01-318, Regon: 011304266, który świadczy usługi drogą elektroniczną oraz przechowuje i uzyskuje dostęp do
	informacji w urządzeniach Użytkownika.</p>
<p>2. <strong>Cookies</strong> – oznacza dane informatyczne, w szczególności niewielkie pliki tekstowe, zapisywane i
	przechowywane na urządzeniach za pośrednictwem, których Użytkownik korzysta ze stron internetowych Serwisu. </p>
<p>3. <strong>Cookies Administratora</strong> – oznacza Cookies zamieszczane przez Administratora, związane ze
	świadczeniem usług drogą elektroniczną przez Administratora za pośrednictwem Serwisu.</p>
<p>4. <strong>Serwis</strong> – oznacza stronę internetową, pod którą Administrator prowadzi serwis internetowy
	ista24.pl.</p>
<p>5. <strong>Urządzenie</strong> – oznacza elektroniczne urządzenie za pośrednictwem, którego Użytkownik uzyskuje
	dostęp do stron internetowych Serwisu.</p>
<p>6. <strong>Użytkownik</strong> – oznacza podmiot, na rzecz którego zgodnie z Regulaminem i przepisami prawa, mogą być
	świadczone usługi drogą elektroniczną, lub z którym zawarta może być Umowa o świadczenie usług drogą elektroniczną.
</p>
<p class="align-center"><strong>§ 2. Rodzaje wykorzystywanych Cookies</strong></p>
<p>1. Stosowane przez Administratora Cookies są bezpieczne dla Urządzenia Użytkownika. W szczególności tą drogą nie jest
	możliwe przedostanie się do Urządzeń Użytkowników wirusów lub innego niechcianego oprogramowania lub oprogramowania
	złośliwego. </p>
<p>Pliki te pozwalają zidentyfikować oprogramowanie wykorzystywane przez Użytkownika i dostosować Serwis indywidualnie
	każdemu Użytkownikowi. Cookies zazwyczaj zawierają nazwę domeny, z której pochodzą, czas przechowywania ich na
	Urządzeniu oraz przypisaną wartość.</p>
<p>2. Administrator wykorzystuje dwa typy plików Cookies:</p>
<p>a. Cookies sesyjne: są przechowywane na Urządzeniu Użytkownika i pozostają tam do momentu zakończenia sesji danej
	przeglądarki. Zapisane informacje są wówczas trwale usuwane z pamięci Urządzenia. Mechanizm Cookies sesyjnych nie
	pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z Urządzenia Użytkownika.</p>
<p>b. Cookies trwałe: są przechowywane na Urządzeniu Użytkownika i pozostają tam do momentu ich skasowania. Zakończenie
	sesji danej przeglądarki lub wyłączenie Urządzenia nie powoduje ich usunięcia z Urządzenia Użytkownika. Mechanizm
	Cookies trwałych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z
	Urządzenia Użytkownika.</p>
<p>3. Użytkownik ma możliwość ograniczenia lub wyłączenia dostępu plików Cookies do swojego Urządzenia. W przypadku
	skorzystania z tej opcji korzystanie z Serwisu będzie możliwe, poza funkcjami, które ze swojej natury wymagają
	plików Cookies.</p>
<p class="align-center"><strong>§ 3. Cele, w jakich wykorzystywane są Cookies</strong></p>
<p>1. Pliki Cookies wykorzystywane są w celu:
<p>a. Dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze
	stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio
	wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb.
<p>b. Tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron
	internetowych, co umożliwia ulepszanie ich struktury i zawartości.
<p>c. Utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie
	Serwisu ponownie wpisywać loginu i hasła.
<p class="align-center"><strong>§ 4. Możliwości określenia warunków przechowywania lub uzyskiwania dostępu przez
	Cookie</strong></p>
<p>1. Użytkownik może samodzielnie i w każdym czasie zmienić ustawienia dotyczące plików Cookies, określając warunki ich
	przechowywania i uzyskiwania dostępu przez pliki Cookies do Urządzenia Użytkownika. Zmiany ustawień, o których mowa
	w zdaniu poprzednim, Użytkownik może dokonać za pomocą ustawień przeglądarki internetowej lub za pomocą konfiguracji
	usługi. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików
	Cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu Cookies na
	urządzeniu Użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików Cookies dostępne są w
	ustawieniach oprogramowania (przeglądarki internetowej). </p>
<p>2. Użytkownik może w każdej chwili usunąć pliki Cookies, korzystając z dostępnych funkcji w przeglądarce
	internetowej, której używa.</p>
<p>3. Ograniczenie stosowania plików Cookies, może wpłynąć na niektóre funkcjonalności dostępne na stronie internetowej
	Serwisu.</p>
