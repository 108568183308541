<ic-default-layout>
	<div *ngIf="(currentUser$ | async) === null" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-top-15">
		<ic-country-selector
			[countries]="supportedCountries$ | async"
			(selected)="onCountrySelected($event)">

		</ic-country-selector>
	</div>
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [ngClass]="{ 'padding-top-15' : currentUser$ | async }">
		<ic-term *ngIf="selectedCountryCode" [countryCode]="selectedCountryCode"></ic-term>

		<div *ngIf="needAcceptance">
			<ic-check-box [countryCode]="tracking.countryCode" [terms]="tracking">
			</ic-check-box>

			<p>
				<strong>
					<span>{{ 'REFUSE_TERMS_INFO' | translate }}</span>
				</strong>
			</p>

			<div class="btn-section">
				<button (click)="onRefuseTerms()" class="btn btn-refuse">
					{{ 'REFUSE_TERMS' | translate }}
				</button>
				<button (click)="onAcceptTerms()" class="btn btn-success">
					{{ 'ACCEPT_TERMS' | translate }}
				</button>
			</div>
		</div>
	</div>
</ic-default-layout>
