<h1>Privatnost podataka</h1>
<h2>Zaštita vaših podataka</h2>
<p>Tvrtka ista članica je Društva za zaštitu podataka i sigurnosnih kopija od veljače 2002. godine. Pratimo sve zakonske
	odredbe koje se odnose na zaštitu podataka te činimo sve kako bi vaši podaci ostali sigurni. Sve osobne informacije
	prikupljene u skladu s odredbama Zakona o zaštiti osobnih podataka (BDSG), koje su propisane u ugovoru, koristimo
	isključivo kako bi ispunili zakonske obveze, odnosno kako bi smo pristupili zahtjevima, savjetima i osobnim
	interesima naših korisnika, te im pružili sigurnost zaštite njihovih podataka.</p>
<p>Našom IT infrastrukturom upravlja IBM. IBM ispunjava certifikat ISO 27001 (globalni standard za upravljanje
	informacijama). Taj atest potvrđuje da IBM ispunjava sve zahtjeve u pogledu sigurnosti, integriteta i povjerljivosti
	podataka.</p>
<h2>Izjava o Privatnosti za E-Mail Marketing</h2>
<p>Ovime pristajem na prikupljanje, korištenje, obradu i diseminaciju mojih podataka od strane tvrtke ista SE u svrhu
	oglašavanja, savjetovanja i istraživanja tržišta, od aktualnih ponuda do zanimljivih informacija, kao što je ista
	Marketing Bilten i INSIDE ista Bilten.</p>
<p>Moj osobni podaci u potpunosti ostaju u tajnosti i ne razmjenjuju se bez prethodnog odobrenja. Za pružanje određenih
	zadataka tvrtka ista koristi vanjske pružatelje usluga koji su povjereni za upravljanje biltenom i vijestima. ista
	osigurava da vanjski pružatelji obrađuju osobne podatke uz najveću moguću pažnju, koristeći ih samo u svrhu
	ispunjenja specifičnih izvedbi.</p>
<p>Za statističke svrhe, radimo anonimne praćenje poveznica.</p>
<p>Svoju suglasnost ili upit u budućnosti mogu u bilo koje vrijeme izraziti putem e-maila na info@ista.hr, ključnom
	riječi: "ista Marketing Bilten" ili „ Otkazivanje ista Bilten pretplate“. Svi osobni podaci će biti izbrisani odmah
	nakon primitka opoziva. Potvrđujem da imam 18 godina.</p>
<h2>Prikupljanje i obrada osobnih informacija</h2>
<p>Svaki put kada posjetite našu web stranicu ili kada preuzimate datoteku, određene vrste informacija automatski se
	prikupljaju i spremaju. Te informacije nije moguće osobno identificirati. Drugim riječima, tvrtka ista ne prati koje
	datoteke preuzima određeni korisnik.</p>
<p>Pri svakom preuzimanju obično prikupljamo sljedeće podatke:</p>
<ul>
	<li>naziv datoteke koja se preuzima</li>
	<li>datum i vrijeme preuzimanja</li>
	<li>veličina prijenosa podataka</li>
	<li>je li datoteka za preuzimeanje uspješno preuzeta</li>
	<li>IP-adresa</li>
	<li>u nekim slučajevima, operativni sustav vašeg računala i koji preglednik koristite</li>
	<li>internetsku adresu s koje ste upravo došli.</li>
</ul>
<p>Nismo u mogućnosti izraditi pojedinačno prepoznatljive profile korisnika. Navedene vrste informacija prikupljaju se
	isključivo u statističke svrhe.</p>
<p>Ova internetska stranica koristi Piwik, open-source-software za statističku analizu pristupa posjetitelja. Piwik
	koristi "kolačiće". To su tekstualne datoteke koje su pohranjene na vašem računalu, a služe u svrhu analiziranja
	korištenja ili optimizacije web stranice. Informacije generirane od strane kolačića prenose se na naš server. IP
	adresa postaje anonimna nakon obrade i prije pohranjivanja. Informacije generirane od strane kolačića o vašem
	korištenju ove web stranice, neće biti dostupne trećoj strani.</p>
