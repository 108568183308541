import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AppErrorService } from '@errors/services/app-error.service';
import { firstValueFrom } from 'rxjs';
import { CurrentUser } from '../models/current-user.model';
import { CurrentUserService } from '../services/current-user.service';
import { PublicPagesService } from '../services/public-pages.service';


export const ensureCurrentUser = (): CanActivateFn => {
  const injectDependencies = () => ({
    currentUser: inject(CurrentUserService),
    appError: inject(AppErrorService),
    publicPages: inject(PublicPagesService)
  });

  return async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const {
      currentUser,
      publicPages,
      appError
    } = injectDependencies();

    let user: CurrentUser;

    try {
      user = await firstValueFrom(currentUser.ensureUser$);
    }
    catch (ex) {
      user = null;
    }

    if (!user && !publicPages.userIsOnPublicPage(state.url)) {
      appError.raise({
        messageKey: 'NO_MATCHING_CURRENT_USER',
        showLink: true,
        // NOTE: logout is the actual action, however from UX perspective we decided to use a different label
        linkText: 'LOGIN',
        linkUrl: '/logout'
      });
    }

    return !!user;
  };
};
