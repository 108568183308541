<h1>Dane firmy</h1>
<div class="imprint">
	<p>ista Polska Sp. z o.o.<br>
		Al. 29 Listopada 155C<br>
		31-406 Kraków<br>
		Strona główna: <a href="http://www.ista.pl/" target="_blank">www.ista.pl</a>
	</p>

	<p>
		Telefon +48 12 651 01 00<br>
		Telefax +48 12 651 01 11
		<br>
		Email: <a href="mailto:ista24@ista.pl">ista24@ista.pl</a>
	</p>

	<p>
		Prezes Zarządu: Maciej Dolny<br>
		NIP: 525-10-01-318<br>
		KRS: 0000010442 wydany przez</p>

	<p>Sąd Rejonowy dla Krakowa Śródmieście w Krakowie,
		XI Wydział Gospodarczy Krajowego Rejestru Sądowego<br>
		Kapitał zakładowy: 15 205 500 PLN
	</p>

</div>
