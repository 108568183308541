import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserActivationFormComponent } from '../user-activation-form/user-activation-form.component';
import { ErrorCodeService } from '../../services/error-code.service';
import { Notification, NotificationLiveTime, NotificationService } from '@shared/notifications';
import { UserActivationApiService } from '@security/user';

@Component({
  template: `
		<ic-default-layout>
			<div class="welcome col-lg-8 col-md-8 col-sm-6">
				<h1 class="page-header">{{ 'USER_ACTIVATION.TITLE' | translate }}</h1>

				<h2>{{ 'USER_ACTIVATION.WELCOME' | translate }}</h2>
				<h2></h2>

				<span [innerHTML]="'USER_ACTIVATION.TEXT' | translate">
				</span>
				<h2></h2>

				<ic-user-activation-form #activationForm
					[hidden]="!showActivationForm"
					(save)="onActivate($event)">
				</ic-user-activation-form>
			</div>
		</ic-default-layout>
	`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserActivationComponent {
  @ViewChild('activationForm', { static: true })
  public activationForm: UserActivationFormComponent;

  public showActivationForm = true;

  public constructor(
    private route: ActivatedRoute,
    private userActivationResource: UserActivationApiService,
    private errorCodes: ErrorCodeService,
    private notification: NotificationService
  ) { }

  public onActivate({ newPassword }: { newPassword: string }) {
    const token = this.route.snapshot.params['token'];

    this.userActivationResource
      .userActivation({ newPassword, token })
      .subscribe({
        next: () => this.onActivated(),
        error: (response) => this.onActivateFailed(response)
      });
  }

  private onActivated() {
    this.activationForm.reset();
    this.showActivationForm = false;

    this.notification.show(
      Notification.inline().asSuccess({
        messageKey: 'USER_ACTIVATION.SUCCESS',
        showLink: true,
        linkUrl: '/',
        linkText: 'GOTO_LOGIN'
      })
    );
  }

  private onActivateFailed(response) {
    const messageKey = this.errorCodes.createErrorMessage(
      response,
      'USER_ACTIVATION',
      'ERROR_DEFAULT',
      true
    );

    this.notification.show(
      Notification.toast().asError({ messageKey, liveTime: NotificationLiveTime.LONG })
    );
  }
}
