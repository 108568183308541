import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormKeys, PasswordStrengthService, ValidationKeys } from '@security/change-password';
import { debounceTime, Subscription } from 'rxjs';
import { FormKeys as NestedFormKeys } from '@security/change-password';

interface FormValue {
  [NestedFormKeys.NEW_PASSWORD]: string;
  [NestedFormKeys.NEW_PASSWORD_CONFIRMATION]: string;
}

@Component({
  selector: 'ic-user-activation-form',
  template: `
		<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate class="form form-horizontal">
			<ic-new-password-fields-section
				[controlHasErrorFn]="controlHasError.bind(this)">
			</ic-new-password-fields-section>

			<button type="submit" class="btn btn-primary btn-activate-user-submit" [disabled]="formGroup.invalid">
				<i class="fa fa-check"></i> {{ 'USER_ACTIVATION.BUTTON' | translate }}
			</button>
		</form>
	`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserActivationFormComponent implements OnInit, OnDestroy {
  @Output()
  public save = new EventEmitter<{ newPassword: string }>();

  @Output()
  public changed = new EventEmitter<void>();

  public formGroup: FormGroup;
  public submitted: boolean;

  private subscriptions = new Subscription();

  public constructor(
    private formBuilder: FormBuilder,
    private passwordStrength: PasswordStrengthService
  ) {
    this.createForm();
  }

  public ngOnInit() {
    this.subscribeToFormChanges();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) { return; }

    const value: FormValue = this.formGroup.value;

    this.save.emit({
      newPassword: value[NestedFormKeys.NEW_PASSWORD]
    });
  }

  public controlHasError(controlName: FormKeys, validatorName?: ValidationKeys) {
    const formControl = this.formGroup.get(controlName);
    const isInvalid = !!formControl.errors
      && (!validatorName || !!formControl.errors[validatorName.toLowerCase()]);

    return formControl.invalid && isInvalid && (!formControl.pristine || this.submitted);
  }

  public reset() {
    this.submitted = false;

    this.formGroup.reset({}, { emitEvent: false });
    this.formGroup.markAsPristine();
  }

  private createForm() {
    this.formGroup = this.formBuilder.group({
      [FormKeys.NEW_PASSWORD]: ['', [Validators.required, this.strongPassword.bind(this)]],
      [FormKeys.NEW_PASSWORD_CONFIRMATION]: ['', [Validators.required]]
    });
  }

  private strongPassword(control: AbstractControl) {
    if (!control.value) { return null; }

    return this.passwordStrength.validate(control.value)
      ? null
      : { [ValidationKeys.STRONG_PASSWORD]: true };
  }

  private subscribeToFormChanges() {
    const subscription = this.formGroup.valueChanges
      .pipe(
        debounceTime(250)
      )
      .subscribe(() => this.changed.emit());

    this.subscriptions.add(subscription);
  }
}
