<h1>Impressum</h1>
<div class="imprint">
	<p>ista SE<br>
		Luxemburger Straße 1<br>
		45131 Essen<br>
	</p>

	<p>Telefon: +49 (0) 201 459-02<br>
		Fax: +49 (0) 201 459-3630<br>
		E-Mail: <a href="mailto:info@ista.de">info@ista.de</a>
	</p>

	<p>Sitz der Gesellschaft: Essen<br>
		Registergericht: Amtsgericht Essen<br>
		Registernummer: HRB 33073</p>

	<p>Vorsitzender des Aufsichtsrates: Dr. Dieter Hackenberg</p>

	<p>Vorstand: Dr. Hagen Lessing, Vorsitzender · Thomas Lemper, stellv. Vorsitzender · Oliver Schlodder</p>

	<p>Umsatzsteuer-Identifikationsnummer: DE 50190920</p>

	<p>Alle Inhalte dieser Website &copy; bei ista SE 2022</p>
</div>
