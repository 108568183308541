import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import { first } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class CustomizedModalService {
  public constructor(
    private modal: NgbModal
  ) { }

  public open(component: any, options?: NgbModalOptions) {
    const instance = this.modal.open(component, this.mergeWithDefault(options));

    this.animateBackdrop();
    this.animateModal(instance);

    return instance;
  }

  private mergeWithDefault(options: NgbModalOptions) {
    const defaultOptions: NgbModalOptions = {
      size: 'lg',
      backdrop: true
    };

    return Object.assign(defaultOptions, options || {});
  }

  private animateBackdrop() {
    setTimeout(() => this.addClassToElement('backdrop', 'in'), 50);
  }

  private animateModal(ref: NgbModalRef) {
    ref.shown
      .pipe(first())
      .subscribe(() => this.addClassToElement('window', 'in'));
  }

  private addClassToElement(role: 'backdrop' | 'window', cssClass: string) {
    const element = this.findElement(role);

    if (!element) { return; }

    element.classList.add(cssClass);
  }

  private findElement(role: 'backdrop' | 'window') {
    const elements = document.getElementsByTagName(`ngb-modal-${role}`);

    if (!elements?.length) { return null; }

    return elements[0];
  }
}
