import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AlertBoxComponent, InlineNotificationComponent, ToastsComponent } from './notifications';
import { icBrand, icBrandFactory, icWorkingDomain, icWorkingDomainFactory } from './branding';
import { CountrySelectorComponent, AcceptCookiesComponent } from './disclaimer';
import {
  NoticeITComponent, NoticePLComponent, NoticeENComponent, NoticeDEComponent, NoticeHRComponent, NoticeROComponent
} from './disclaimer/notices';
import {
  TermsPLComponent, TermsROComponent, TermsCZComponent, TermComponent, TermsDEComponent, TermsITComponent
} from './disclaimer/terms';
import {
  PolicyPLComponent, PolicyROComponent, PolicyITComponent, PolicyDEComponent, PolicyGBComponent, PolicyHRComponent
} from './disclaimer/policies';
import { AttachSpinnerToMatchingRequestInterceptor } from './spinner';
import { NumberFormatPipe, DasherizePipe, OrderByPipe, MomentFormatPipe } from './common';
import { ControlTooltipComponent, AttentionComponent } from './consumption';
import {
  DeleteConfirmationModalComponent
} from './modals';
import {
  InlineFilterDropDownComponent,
  InlineSortingComponent,
  ListPaginationComponent,
  InlineFilterTextComponent
} from './data-grid';
import {
  FallbackImageSourceDirective,
  NgIncludeDirective,
  TotalNumberLocalizedComponent,
  AnimatedNumberComponent,
  TemplateWithIdDirective
} from './layout';
import { CheckBoxComponent, DatePickerComponent, ToggleSwitchComponent } from './forms';
import { AppFooterComponent } from './layout/app-footer.component';
import { SpinnerComponent } from './layout/spinner.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    NgxSpinnerModule
  ],
  declarations: [
    AlertBoxComponent,
    FallbackImageSourceDirective,
    AcceptCookiesComponent,
    PolicyDEComponent,
    PolicyHRComponent,
    PolicyGBComponent,
    PolicyITComponent,
    PolicyPLComponent,
    PolicyROComponent,
    NgIncludeDirective,
    NoticeDEComponent,
    NoticeENComponent,
    NoticeHRComponent,
    NoticeITComponent,
    NoticePLComponent,
    NoticeROComponent,
    CheckBoxComponent,
    ToggleSwitchComponent,
    AttentionComponent,
    ToastsComponent,
    InlineNotificationComponent,
    MomentFormatPipe,
    NumberFormatPipe,
    DasherizePipe,
    OrderByPipe,
    AnimatedNumberComponent,
    ControlTooltipComponent,
    TemplateWithIdDirective,
    DeleteConfirmationModalComponent,
    InlineFilterTextComponent,
    InlineFilterDropDownComponent,
    TotalNumberLocalizedComponent,
    ListPaginationComponent,
    InlineSortingComponent,
    DatePickerComponent,
    TermsCZComponent,
    TermsDEComponent,
    TermsITComponent,
    TermsPLComponent,
    TermsROComponent,
    TermComponent,
    CountrySelectorComponent,
    AppFooterComponent,
    SpinnerComponent
  ],
  exports: [
    AlertBoxComponent,
    FallbackImageSourceDirective,
    AcceptCookiesComponent,
    NgIncludeDirective,
    CheckBoxComponent,
    ToggleSwitchComponent,
    AttentionComponent,
    ToastsComponent,
    InlineNotificationComponent,
    MomentFormatPipe,
    NumberFormatPipe,
    DasherizePipe,
    OrderByPipe,
    AnimatedNumberComponent,
    ControlTooltipComponent,
    TemplateWithIdDirective,
    DeleteConfirmationModalComponent,
    InlineFilterTextComponent,
    InlineFilterDropDownComponent,
    TotalNumberLocalizedComponent,
    ListPaginationComponent,
    InlineSortingComponent,
    DatePickerComponent,
    TermComponent,
    CountrySelectorComponent,
    AppFooterComponent,
    SpinnerComponent
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: icWorkingDomain, useFactory: icWorkingDomainFactory },
        { provide: icBrand, useFactory: icBrandFactory, deps: [icWorkingDomain] },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AttachSpinnerToMatchingRequestInterceptor,
          multi: true
        }
      ]
    };
  }
}
