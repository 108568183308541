import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  EMPTY, map,
  Observable, shareReplay,
  switchMap,
  take
} from 'rxjs';

import { ContactFormInitialValue, SendContactFormPayload } from '../models';
import { NotificationService, Notification } from '@shared/notifications';
import { LabelValuePair } from '@shared/common';
import { CountryResource } from '@shared/localization';
import { ContactApiService } from './contact-api.service';
import { CurrentUserService } from '@security/user';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  public countries$: Observable<LabelValuePair[]>;
  public initialValue$: Observable<ContactFormInitialValue>;

  private countriesBus = new BehaviorSubject<void>(undefined);
  private initialValueBus = new BehaviorSubject<void>(undefined);

  public constructor(
    private countryResource: CountryResource,
    private resource: ContactApiService,
    private notifications: NotificationService,
    private currentUser: CurrentUserService
  ) {
    this.subscribeToCountries();
    this.subscribeToInitialValue();
  }

  public initialize() {
    this.initialValueBus.next();
  }

  public send(payload: SendContactFormPayload) {
    const method = !!payload?.userUuid
      ? this.resource.sendSecured(payload)
      : this.resource.sendUnsecured(payload);

    return method.pipe(
      catchError(error => {
        this.notifications.show(
          Notification.toast().asError({
            messageKey: 'CONTACT.SEND_FAILURE'
          })
        );
        return EMPTY;
      })
    );
  }

  private subscribeToCountries() {
    this.countries$ = this.countriesBus.pipe(
      switchMap(() => this.countryResource.getCountries(false)),
      map(countries => countries.map(
        country => country.countryCode
      )),
      map(countryCodes => countryCodes.map(
        countryCode => ({ label: countryCode, value: countryCode })
      )),
      shareReplay(1)
    );
  }

  private subscribeToInitialValue() {
    this.initialValue$ = this.initialValueBus.pipe(
      switchMap(() => this.currentUser.user$.pipe(take(1))),
      map(user => !user
        ? ({ userUuid: '' })
        : ({
          userUuid: user.uuid,
          name: user.firstName,
          emailAddress: user.emailAddress
        })
      ),
      shareReplay(1)
    );
  }
}
