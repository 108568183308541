<div class="terms-content">
    <h1>Termeni de utilizare ai Portalul web ista (din mai 2018)</h1>
    <p><strong>1. Operatorul</strong></p>
    <p><strong>ista România SRL</strong> (numită în continuare <strong>ista</strong>) este operatorul portalului web (numit în continuare &quot;Portalul Web&quot;). Utilizarea Portalului Web, inclusiv a funcțiilor sale, se realizează printr-un contract privind folosirea Portalului Web între <strong>ista</strong> și utilizator. Utilizarea Portalului Web și a conținutului prezentat pe acesta este supus următorilor Termeni de utilizare.</p>
    <p><strong>2. Termeni generali de utilizare ai Portalul Web</strong></p>
    <p>Pentru a utiliza Portalul Web este necesară înregistrarea cu succes folosind numele de utilizator și parola. <strong>ista</strong> furnizează iniţial utilizatorului aceste detalii şi sfătuiește utilizatorul să înlocuiască imediat parola emisă de <strong>ista</strong> cu o parolă individuală și sigură. Utilizatorul este responsabil pentru protejarea detaliilor de autentificare de la accesul neautorizat al terților; el trebuie să informeze imediat <strong>ista</strong> despre utilizarea neautorizată și, de asemenea, să-și schimbe parola.</p>
    <p><strong>ista</strong> furnizează utilizatorului serviciile din Portalul Web numai în scopuri proprii, pe baza condițiilor cadru tehnice, juridice și comerciale actuale, adică nu pot fi revândute, transmise sau divulgate terţilor, servicii și informații de orice fel accesate prin intermediul Portalului Web. Portalul Web poate fi utilizat numai într-un mod legal și in conformitate cu conditiile contractuale și se supune acestor Termeni de utilizare. Utilizarea neautorizată a datelor și a informațiilor, de ex. înregistrarea neautorizată a datelor de către terți sau în numele acestora este interzisă. În plus, este interzisă utilizarea oricăror informații necorespunzătoare, indiferent de cine sau la ce se referă informațiile. Portalul Web nu poate fi folosit pentru tranzacții frauduloase sau alte acțiuni relevante conform dreptului penal. Utilizatorul se angajează să exonereze angajații și compania <strong>ista</strong> de la orice pretenții ale unor terțe părți care rezultă din utilizarea necontractuală, neautorizată sau de altă natură a Portalului Web sau a conținutului acestuia. <strong>ista</strong> are dreptul să efectueze în orice moment modificări ale Portalului Web și/sau a conținutului său, să înceteze, să oprească temporar sau să blocheze parțial utilizarea Portalului Web în orice moment prin blocarea accesului, fără ca utilizatorul să aibă reclamații împotriva <strong>ista.</strong> Utilizatorul nu trebuie sa revendice dreptul de acces sau disponibilitatea Portalului Web.</p>
    <p><strong>3. Limitări ale răspunderii</strong></p>
    <p>Responsabilitatea <strong>ista</strong> pentru transferul de date încetează în momentul în care datelesunt predate furnizorului de internet al <strong>ista</strong>. În cazul unei încălcări a obligațiilor precontractuale, contractuale și extra contractuale, inclusiv a unei livrări defectuoase sau a altor servicii și a unor acțiuni ilegale, <strong>ista</strong> este răspunzătoare numai în caz de intenție, neglijență gravă sau de încălcare ușor neglijentă a unei obligații contractuale importante (obligație contractuală a cărei încălcare pune în pericol atingerea scopului contractual). Cu toate acestea, răspunderea - cu excepția cazului de intenție - este limitată la prejudiciile tipice contractuale, previzibile la încheierea contractului. Răspunderea pentru ușoare neglijențe este exclusă, afara de cazul în care este încălcată o obligație importantă, excepție făcând dobânzile implicite. <strong>ista</strong> este răspunzătoare pentru dobânda implicită chiar și în caz de neglijență ușoară, dar numai în cuantum de 5% din prețul de achiziție convenit cu <strong>ista</strong> sau remunerația pentru alt serviciu.</p>
    <p>Răspunderea pentru produs în conformitate cu Legea nr. 240/2004 rămâne neschimbată. Același lucru este valabil și pentru răspunderea pentru pagube cauzate prin punerea în pericol a vieții, vătămare a corpului sau a sănătății. De asemenea, ista nu va fi răspunzătoare pentru daunele rezultate din utilizarea neautorizată a datelor de conectare ale utilizatorului. Limitările de răspundere de mai sus se aplică și răspunderii personale a angajaților, a reprezentanților legali și a agenților delegați. ista depune toate eforturile rezonabile pentru a furniza informații complete în acest Portal Web. ista nu își asumă nicio responsabilitate și nu garantează că informațiile furnizate pe acest site sunt actualizate, corecte și complete. Acest lucru se aplică și legăturilor directe sau indirecte de pe Portalul Web. ista nu este responsabilă pentru conținutul unui site de internet accesat printr-o astfel de legătură. ista nu va fi răspunzătoare pentru daune directe sau indirecte, inclusiv profitul pierdut, care rezultă din sau în legătură cu informațiile furnizate pe Portalul Web.</p>
    <p><strong>4. Costuri</strong></p>
    <p>Nu vor fi percepute costuri suplimentare pentru utilizarea site-ului web, cu excepția costurilor de furnizor și de telecomunicații suportate de utilizator. Orice servicii <strong>ista</strong> comandate prin intermediul Portalului Web sunt la preturile convenite intre <strong>ista</strong> si utilizator sau conform preturilor din lista actuala de preturi a <strong>ista.</strong></p>
    <p><strong>5. Drepturi de utilizare</strong></p>
    <p>Toate drepturile, în special drepturile de utilizare și cele de exploatare a drepturilor de autor asupra conținutului Portalului Web, sunt proprietatea exclusivă a <strong>ista</strong> în relația sa cu utilizatorul.</p>
    <p><strong>6. Protecția datelor</strong></p>
    <p>Protecția datelor Dumneavoastră este foarte importantă pentru <strong>ista</strong>.</p>
    <p><strong>ista</strong> respectă reglementările legale privind protecția datelor și face totul pentru a păstra confidențialitatea datelor Dvs. Toate datele cu caracter personal sunt colectate, prelucrate și utilizate în conformitate cu prevederile Regulamentului general privind protecția datelor nr.679/2016 (GDPR) și numai în scopuri contractuale, al îndeplinirii obligațiilor legale sau al protejării propriilor interese legitime de afaceri în ceea ce privește consultanța și sprijinul clienților, precum și proiectarea produsului orientată spre cerere.</p>
    <p>Toți furnizorii de servicii ai <strong>ista</strong> din domeniul de prelucrare a datelor cu caracter personal respectă de asemenea prevederile GDPR, în conformitate cu art. 28 GDPR. Conformitatea este monitorizată de responsabilul nostru pentru protecția datelor.</p>
    <p><strong>6.1. Colectarea și prelucrarea datelor cu caracter personal</strong></p>
    <p>De fiecare dată când un utilizator accesează site-ul <strong>ista</strong> și de fiecare dată când un fișier este accesat, datele despre acea activitate sunt stocate într-un fișier jurnal. Aceste date nu sunt legate de o persoană, astfel încât <strong>ista</strong> nu poate vedea care utilizator și ce date a accesat.</p>
    <p>În detaliu, următoarea înregistrare de date este salvată pentru fiecare accesare:</p>
    <p>• numele fișierului</p>
    <p>• data și ora accesului</p>
    <p>• cantitatea de date transferate</p>
    <p>• mesaj dacă solicitarea a avut succes</p>
    <p>• adresă IP anonimizată</p>
    <p>• dacă este necesar, sistemul de operare și browser-ul de pe computerul Dvs.</p>
    <p>• site-ul web în urma căruia ați vizitat site-ul <strong>ista</strong></p>
    <p>Nu pot fi create profiluri personale de utilizatori.</p>
    <p>Datele menționate anterior sunt evaluate doar în scopuri statistice.</p>
    <p>Datele cu caracter personal vor fi colectate numai dacă le oferiți către <strong>ista</strong> in mod liber consimtit - de exemplu, atunci când vă înregistrați pentru un sondaj sau pentru a încheia un contract. Transmiterea datelor Dvs. către terțe părți nu are loc, cu excepția cazului în care <strong>ista</strong> este obligată din punct de vedere legal să facă acest lucru. În măsura în care furnizorii de servicii externi intră în contact cu datele Dvs. cu caracter personal, <strong>ista</strong> se asigură că aceștia respectă prevederile legale privind protecția datelor prin măsuri juridice, tehnice și organizatorice, precum și verificări periodice.</p>
    <p><strong>Cookie-uri</strong></p>
    <p><strong>ista</strong> utilizează așa-numitele cookie-uri pe site-ul <strong>ista</strong> pentru a recunoaște utilizarea repetată a portalului de către același utilizator de conexiune. Fișierele cookie sunt fișiere text de mici dimensiuni pe care browserul de internet le plasează şi le salvează pe computerul Dvs. Ele sunt folosite pentru a optimiza ceea ce oferim prin site-ul nostru. Acestea sunt de obicei așa-numitele ″cookie-uri de sesiune″ care sunt șterse la sfârșitul vizitei dumneavoastră pe site.</p>
    <p>Cu toate acestea, cookie-urile conțin uneori informații care vă permit să fiți recunoscuți automat. Această recunoaștere se bazează pe adresa IP stocată în cookie-uri. Informațiile obținute în acest mod sunt utilizate pentru a îmbunătăți oferta noastră și pentru a vă oferi un acces mai ușor la site.</p>
    <p>Puteți refuza utilizarea cookie-urilor prin selectarea setărilor corespunzătoare din browser. Cu toate acestea, <strong>ista</strong> evidențiază faptul că astfel este posibil să nu puteți utiliza complet funcționalitatile site-ului <strong>ista</strong>.</p>
    <p><strong>Analiza web</strong></p>
    <p>Acest site utilizează Matomo, un software open source pentru evaluarea statistică a accesului utilizatorilor. Matomo utilizează "cookie-urile", fișiere text stocate pe computerul Dvs. și care servesc la analizarea utilizării sau a optimizării site-ului web. În acest scop, informațiile de utilizare generate de cookie sunt transmise serverului ista. Adresa IP este anonimizată imediat după prelucrare și înainte de stocarea acesteia. Informațiile generate de cookie-uri despre utilizarea acestui site de către Dvs. nu vor fi divulgate unor terțe părți. Pentru a accepta sau a respinge analiza web, vă rugăm să accesați partea de jos a paginii.</p>
    <p><strong>6.2. Drepturile persoanelor vizate</strong></p>
    <p>În conformitate cu prevederile Regulamentului General pentru Protecția Datelor cu Caracter Personal nr. 679/2016 (GDPR), <strong>ista</strong> ia măsurile adecvate pentru a furniza persoanei vizate toate informațiile și comunicările legate de prelucrare într-o formă precisă, transparentă, ușor de înțeles și ușor accesibilă, într-un limbaj clar și simplu. Informațiile sunt transmise în scris sau în orice altă formă, inclusiv, dacă este cazul, pe cale electronică.</p>
    <p>Deoarece <strong>ista</strong> procesează anumite date cu caracter personal în mod automat, vă comunicăm că vă puteți exercita drepturile conf. Art .13 GDPR, prin cerere scrisă, datată și semnată, depusă la sediul <strong>ista</strong> România sau transmisă la adresa <a href="mailto:protectiadatelor@ista.ro"><em>protectiadatelor@ista.ro</em></a></p>
    <p>Scopul pentru care vor fi prelucrate datele cu caracter personal și legalitatea prelucrării îl constituie optimizarea satisfacției clienților și a site-ului, Art. 6 paragraful 1 din GDPR.</p>
    <p>Interesul legitim in acest sens, trebuie privit în special prin prisma prelucrării pseudonimizate în scopul optimizării site-ului web.</p>
    <p><strong>Ștergerea datelor și durata stocării</strong></p>
    <p>Datele cu caracter personal ale persoanelor vizate nu vor putea fi accesate sau vor fi șterse de îndată ce stocarea lor nu mai este justificată. Mai mult, datele pot fi păstrate, dacă acest lucru este prevăzut în Legislația Europeană sau națională, prin legi, norme sau alte prevederi la care operatorul trebuie să se supună. De asemenea, datele vor fi blocate sau șterse dacă expiră o perioadă de stocare prevăzută de legislația menționată mai sus, cu excepția cazului în care este necesară stocarea ulterioară a datelor pentru încheierea sau îndeplinirea unui contract.</p>
    <p><strong>Newsletter</strong></p>
    <p><strong>ista</strong> vă oferă opțiunea de a vă abona la un newsletter (buletin informativ) pe pagina web <strong>ista</strong>. Prin acest buletin informativ veți primi informații despre subiecte și oferte <strong>ista</strong> la intervale periodice. Pentru a primi acest buletin informativ, aveți nevoie de o adresă de e-mail validă. Adresa Dvs. de e-mail introdusă va fi verificată de <strong>ista</strong> pentru acuratețe și caracterul complet al acesteia. Datele Dvs. de conectare, precum și adresa IP oficială, data și ora vor fi stocate. Acest lucru servește ca măsură de siguranță pentru a împiedica folosirea greșită a adresei Dvs. de e-mail de către terți neautorizați. Nu vor fi stocate alte date de către <strong>ista</strong>. Datele colectate vor fi folosite doar pentru expedierea buletinelor informative. <strong>ista</strong> se obligă să nu transmită datele colectate către terțe părți. Aveți opțiunea de a vă dezabona în orice moment, fără a preciza motivele și a vi se solicita informații din partea <strong>ista</strong>. Detaliile sunt marcate în fiecare buletin informativ.</p>
    <p><strong>6.3. Modificări ale informațiilor privind protecția datelor</strong></p>
    <p><strong>ista</strong> își rezervă dreptul de a modifica aceste informații în orice moment, având în vedere reglementările în vigoare privind protecția datelor; ultima actualizare a fost în mai 2018.</p>
    <p>Dacă aveți întrebări cu privire la prelucrarea sau securitatea datelor dvs. cu caracter personal, puteți contacta direct responsabilul <strong>ista</strong> pentru protecția datelor la adresa <a href="mailto:protectiadatelor@ista.ro"><em>protectiadatelor@ista.ro</em></a> .</p>
    <p><strong>7. Acord privind prelucrarea datelor cu caracter personal de către Persoane Împuternicite de Operator</strong></p>
    <p>Lista Persoanelor Împuternicite în conformitate cu articolul 28 GDPR poate fi pusă la dispoziţie la cererea utilizatorilor Portalului Web.</p>
    <p><strong>8. Instanța competentă</strong></p>
    <p>Orice litigiu apărut se va soluţiona pe cale amiabilă. Daca soluţionarea pe cale amiabilă nu este posibilă, atunci diferendul va fi deferit spre competentă soluţionare instanţelor de judecată.</p>
</div>
