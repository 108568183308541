import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { filter, map } from 'rxjs';
import { LanguageSelectionService } from '@shared/localization';

@Pipe({
  name: 'numberFormat$'
})
export class NumberFormatPipe implements PipeTransform {
  public constructor(
    private languageSelection: LanguageSelectionService
  ) { }

  public transform(value: number | string, ...args: string[]) {
    if (!args.length) { throw new Error('NumberFormatPipe: no arguments passed'); }

    const numberToFormat = typeof value === 'string' ? parseInt(value, 10) : value;

    return this.languageSelection.locale$.pipe(
      filter(locale => !!locale),
      map((locale) => formatNumber(numberToFormat, locale.lang, args[0]))
    );
  }

}
