<div class="form-group form-group-new-password"
	 [ngClass]="{ 'has-error' : controlHasError(FormKeys.NEW_PASSWORD) }">

  <div class="col-lg-3 col-md-3 col-sm-4">
    <label for="newPassword">
      {{ 'NEW_PASSWORD_LABEL' | translate }}
      <i class="fa fa-exclamation-circle" style="margin-left: 2px;"
         title="{{ 'PASSWORD_POLICY_DESCRIPTION' | translate }} {{ 'ERROR_PASSWORD_RECENTLY_USED' | translate }}"></i>
    </label>
    <input [formControlName]="FormKeys.NEW_PASSWORD" name="newPassword" id="newPassword"
         class="form-control" type="password" autocomplete="off"
         (change)="changed.next()"/>
  </div>
  <div class="col-xs-12">
    <p id="test-errorPassword" *ngIf="controlHasError(FormKeys.NEW_PASSWORD)" class="help-block" style="max-width: 400px;">
      <ng-container *ngIf="controlHasError(FormKeys.NEW_PASSWORD, ValidationKeys.REQUIRED)">
        {{ "ERROR_PASSWORD_REQUIRED" | translate }}
      </ng-container>
      <ng-container *ngIf="controlHasError(FormKeys.NEW_PASSWORD, ValidationKeys.STRONG_PASSWORD)">
        {{ "ERROR_PASSWORD_TOO_WEAK" | translate }}
      </ng-container>
    </p>
  </div>
</div>

<div class="form-group form-group-new-password-confirmation"
	 [ngClass]="{ 'has-error' : controlHasError(FormKeys.NEW_PASSWORD_CONFIRMATION) || formGroupHasError() }">
  <div class="col-lg-3 col-md-3 col-sm-4">
    <label for="newPasswordConfirmation">
      {{ 'NEW_PASSWORD_CONFIRMATION_LABEL' | translate }}
    </label>
    <input [formControlName]="FormKeys.NEW_PASSWORD_CONFIRMATION" type="password"
         id="newPasswordConfirmation" name="newPasswordConfirmation"
         class="form-control" autocomplete="off"
         (change)="changed.next()">
  </div>
  <div class="col-xs-12">
    <p id="test-errorPasswordConfirm" *ngIf="formGroupHasError('newPasswordsMatch')"
       class="help-block">
      {{ 'ERROR_PASSWORD_CONFIRMATION' | translate }}
    </p>
  </div>
</div>
