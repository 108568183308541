import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class TermsResource {

  public constructor(
    private http: HttpClient
  ) { }

  public accept({ userUuid, trackingAccepted }: { userUuid: string; trackingAccepted: boolean }) {
    const payload = { trackingAccepted };

    return this.http.post(
      `${environment.restEndpoint}/user/${userUuid}/acceptTerms`,
      payload
    );
  }
}
