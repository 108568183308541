<h1>Datenschutz bei ista</h1>
<h2>Den Schutz Ihrer Daten nehmen wir sehr ernst.</h2>
<p>ista ist seit Februar 2002 Mitglied bei der Gesellschaft für Datenschutz und Datensicherung e.V.</p>
<p>Wir befolgen selbstverständlich die gesetzlichen Bestimmungen zum Datenschutz und tun alles, um Ihre Daten
	vertraulich zu halten. Alle anfallenden personenbezogenen Daten werden entsprechend den Bestimmungen des
	Bundesdatenschutzgesetzes zum Schutz personenbezogener Daten (BDSG) und nur zum Zwecke der Vertragsabwicklung, zur
	Erfüllung gesetzlicher Verpflichtungen bzw. zur Wahrung berechtigter eigener Geschäftsinteressen im Hinblick auf die
	Beratung und Betreuung unserer Kunden sowie die bedarfsgerechte Produktgestaltung erhoben, verarbeitet und
	genutzt.</p>
<p>Alle Dienstleister von ista, die mit der Verarbeitung personenbezogener Daten zu tun haben, erfüllen ebenfalls die
	Bestimmungen des Bundesdatenschutzgesetzes gemäß § 11 BDSG. Die Einhaltung wird von unserem Datenschutzbeauftragten
	überwacht.</p>
<h2>Erhebung und Verarbeitung persönlicher Daten</h2>
<p>Bei jedem Zugriff eines Nutzers auf die Website von ista und bei jedem Abruf einer Datei werden Daten über diesen
	Vorgang in einer Protokolldatei gespeichert. Diese Daten sind nicht personenbezogen; wir können also nicht
	nachvollziehen, welcher Nutzer welche Daten abgerufen hat.</p>
<p>Im Einzelnen wird über jeden Abruf folgender Datensatz gespeichert:</p>
<ul>
	<li>Name der abgerufenen Datei,</li>
	<li>Datum und Uhrzeit des Abrufs,</li>
	<li>übertragene Datenmenge,</li>
	<li>Meldung, ob der Abruf erfolgreich war,</li>
	<li>anonymisierte IP - Adresse,</li>
	<li>ggfs. Betriebssystem und Browsersoftware Ihres Computers,</li>
	<li>sowie die Website von der aus Sie uns besuchen.</li>
</ul>
<p>Personenbezogene Nutzerprofile können daher nicht gebildet werden.</p>
<p>Die o.g. Daten werden nur zu statistischen Zwecken ausgewertet.</p>
<p>Persönliche Daten werden nur dann erhoben, wenn Sie uns diese von sich aus zum Beispiel im Rahmen einer Registrierung
	bei unserer Online-Bewerbung, einer Umfrage, oder zur Durchführung eines Vertrages angeben. Eine Übermittlung Ihrer
	Daten an Dritte findet nicht statt, es sei denn, wir sind gesetzlich dazu verpflichtet. Soweit externe Dienstleister
	mit Ihren personenbezogenen Daten in Berührung kommen, haben wir durch rechtliche, technische und organisatorische
	Maßnahmen sowie durch regelmäßige Kontrollen sichergestellt, dass diese die Vorschriften der Datenschutzgesetze
	einhalten.</p>
<p>Diese Website benutzt Piwik, eine Open-Source-Software zur statistischen Auswertung der Benutzerzugriffe. Piwik
	verwendet "Cookies", das sind Textdateien, die auf Ihrem Computer gespeichert werden und der Analyse der Benutzung
	der Website bzw. der Websiteoptimierung dienen. Zu diesem Zweck werden die durch den Cookie erzeugten
	Nutzungsinformationen an unseren Server übertragen. Die IP-Adresse wird sofort nach der Verarbeitung und vor deren
	Speicherung anonymisiert. Die durch den Cookie erzeugten Informationen über ihre Nutzung dieser Website werden nicht
	an Dritte weitergegeben.</p>
<p>Auf dieser Website werden mit Technologien der etracker GmbH (www.etracker.com) Daten zu Marketing- und
	Optimierungszwecken gesammelt und gespeichert. Aus diesen Daten können unter einem Pseudonym Nutzungsprofile
	erstellt werden. Hierzu können Cookies eingesetzt werden. Bei Cookies handelt es sich um kleine Textdateien, die
	lokal im Zwischenspeicher des Internetbrowsers des Seitenbesuchers gespeichert werden. Die Cookies ermöglichen die
	Wiedererkennung des Internetbrowsers. Die mit den etracker-Technologien erhobenen Daten werden ohne die gesondert
	erteilte Zustimmung des Betroffenen nicht dazu benutzt, den Besucher dieser Website persönlich zu identifizieren und
	nicht mit personenbezogenen Daten über den Träger des Pseudonyms zusammengeführt. Der Datenerhebung und -speicherung
	kann jederzeit mit Wirkung für die Zukunft widersprochen werden ("Sie wurden von der Zählung ausgeschlossen").</p>
<p>Diese Website verwendet die Remarketing- oder „Ähnliche Zielgruppen“-Funktion der Google Inc. (1600 Amphitheatre
	Parkway, Mountain View, CA 94043, USA; „Google“).</p>
<p>Mit Hilfe dieser Funktion können Ihnen interessensbezogene Anzeigen auf Webseiten des Google Display-Netzwerks
	gezeigt werden. Dazu wird bei einem Webseitenbesuch ein Cookie in Ihrem Browser gespeichert. Dies dient dazu Sie als
	Besucher unserer Website wieder zu erkennen und Besuche und Nutzungsdaten zu ermitteln.</p>
<p>Laut eigenen Angaben von Google werden Serverprotokolle gespeichert, in denen Teile der IP-Adresse und
	Cookie-Information nach 9 bis 18 Monaten teilweise gelöscht werden. Weitere Informationen dazu finden Sie unter <a
			href="http://www.google.com/policies/technologies/ads/">http://www.google.com/policies/technologies/ads/</a>.
	<br>
	Ein Beispiel für ein Serverprotokoll wird unter folgendem Link von Google zur Verfügung gestellt: <a
			href="https://www.google.com/intl/de/policies/privacy/key-terms/#toc-terms-server-logs">https://www.google.com/intl/de/policies/privacy/key-terms/#toc-terms-server-logs</a>.
</p>
<p>Es gibt mehrere Möglichkeiten wie Cookies im Browser deaktiviert werden können, um den eigenen Browser vom
	Remarketing auszuschließen.</p>
<p>Google stellt unter folgendem Link die Möglichkeit bereit ein Browser-Plug-in herunterzuladen, das diese Cookies
	dauerhaft deaktiviert: <a href="https://www.google.com/settings/ads/plugin">https://www.google.com/settings/ads/plugin</a>.
</p>
<p>Sie können auch selbst Einstellungen in Ihrem Browser vornehmen, die die Installation von Cookies deaktivieren (Links
	dazu finden Sie im Folgeabschnitt).</p>
<p>Es können auch durch Drittanbieter Cookies des Browsers deaktiviert werden. Die Netzwerkwerbeinitiative (NAI) bietet
	die Möglichkeit ein Opt-out für den eigenen Browser umzusetzen. Unter folgender Seite finden sich Informationen
	dazu: <a href="http://optout.networkadvertising.org/choices/">http://optout.networkadvertising.org/choices/</a>.</p>
<p>Diese Website nutzt das Conversion-Tracking (Besuchsaktionsauswertung) von „Google-Adwords“, ein Dienst der Google
	Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“).</p>
<p>Wenn Sie auf eine bei Google geschaltete Anzeige klicken, wird auf Ihrem Rechner ein Cookie gespeichert. Jedes
	gesetzte Cookie unterscheidet sich und wird 90 Tage nach einem Klick ungültig. Daher kann der Dienst nicht zur
	Identifizierung einer natürlichen Person genutzt werden.</p>
<p>Wenn das Cookie noch aktiviert ist und Sie bestimmte Internetseiten auf unserer Website besuchen, können Google und
	wir erkennen, dass Sie auf eine von uns geschaltete Anzeige geklickt haben und damit auf unsere Seite weitergeleitet
	wurden.</p>
<p>Die gesammelten Informationen werden von Google genutzt um für Adwords-Kunden Statistiken zu erstellen. Diese
	Statistiken beinhalten Informationen über die Anzahl der Nutzer, die auf Anzeigen geklickt und damit auf Seiten
	weitergeleitet wurden, die mit einem Conversion-Tracking-Tag versehen wurden.</p>
<p>Wenn Sie dem Tracking widersprechen möchten, können Sie das tun, indem Sie die Einstellungen in Ihrem Browser
	bezüglich Cookie-Installationen anpassen. Hinweise dazu können Sie unter folgenden Links finden:</p>
<p>Firefox: <a href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a>
</p>
<p>Internet Explorer: <a
		href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a>
</p>
<p>Chrome: <a href="https://support.google.com/accounts/answer/32050?hl=de">https://support.google.com/accounts/answer/32050?hl=de</a>
</p>
<p>Safari: <a href="https://support.apple.com/kb/PH21411?locale=de_DE ">https://support.apple.com/kb/PH21411?locale=de_DE</a>
</p>
<p>Die Datenschutzerklärung von Google finden Sie hier: <a href="http://www.google.de/policies/privacy/">http://www.google.de/policies/privacy/</a>
</p>
<h2>Nutzung und Weitergabe persönlicher Daten und Zweckbindung</h2>
<p>Besucher unserer Webseite werden stets davon in Kenntnis gesetzt, wenn ihre Informationen an Dritte übermittelt
	werden. Sie können also entscheiden, ob Sie einer Weitergabe Ihrer personenbezogenen Daten an Dritte zustimmen, oder
	nicht.</p>
<p>Wenn Sie unseren Newsletter abonnieren möchten, müssen Sie Ihre E-Mail-Adresse angeben, an die wir den Newsletter
	verschicken können. Mit Angabe Ihrer E-Mail-Adresse willigen Sie ein, dass wir diese zur Versendung des Newsletters
	verwenden, um Sie über Neuigkeiten zu unseren Produkten und Dienstleistungen zu informieren.</p>
<p>Selbstverständlich haben Sie die Möglichkeit, sich jederzeit vom Newsletter abzumelden und die von Ihnen erteilte
	Einwilligung für die Zukunft zu widerrufen. Hierzu klicken Sie bitte auf die entsprechende Schaltfläche im
	zugesandten Newsletter.</p>
<p>Auf unserer Webseite sind so genannte Social Bookmarks (bspw. von Linkedin, Twitter, kununu und Xing) integriert.
	Social Bookmarks sind Internet-Lesezeichen, mit denen die Nutzer eines solchen Dienstes Links und
	Nachrichtenmeldungen sammeln können. Diese sind auf unserer Webseite lediglich als Link zu den entsprechenden
	Diensten eingebunden. Nach dem Anklicken der eingebundenen Grafik werden Sie auf die Seite des jeweiligen Anbieters
	weitergeleitet, d.h. erst dann werden Nutzerinformationen an den jeweiligen Anbieter übertragen. Informationen zum
	Umgang mit Ihren personenbezogenen Daten bei Nutzung dieser Webseiten entnehmen Sie bitte den jeweiligen
	Datenschutzbestimmungen der Anbieter.</p>
<p>Wichtiger Hinweis: Diese Datenschutzhinweise gelten ausschließlich für das Internet-Angebot von ista. Das
	Internet-Angebot von ista enthält Links zu anderen Web-Sites. Bitte beachten Sie, dass ista für deren Datenschutz
	oder auch den Inhalt dieser anderen Internet-Angebote nicht verantwortlich ist. Wir empfehlen allen
	Internet-Benutzern, beim Verlassen des Internet-Angebotes von ista sich über die jeweiligen Datenschutzhinweise der
	anderen Internet-Angebote zu informieren.</p>
<h2>Änderungen der Datenschutzerklärung</h2>
<p>Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit unter Beachtung der geltenden
	Datenschutzvorschriften zu ändern. Derzeitiger Stand ist Juli 2016.</p>
<p>Wenn Sie Fragen hinsichtlich der Verarbeitung oder Sicherheit Ihrer persönlichen Daten haben, können Sie sich direkt
	an unseren Beauftragten für den Datenschutz wenden: </p>
<p>ista SE</p>
<p>Datenschutzbeauftragter</p>
<p>Grugaplatz 2</p>
<p>45131 Essen</p>
<p>E-mail: <a href="mailto:Datenschutz@ista.de">Datenschutz@ista.de</a></p>
