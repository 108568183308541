<div class="row" style="margin-top: 16px;">
  <div class="col-sm-12">
    <div class="form-group-title">
      <label>
        <span>{{ translationPrefix.include('SETTINGS_MFA.HOW_IT_WORKS.TITLE') | translate }}</span>
      </label>
      <div>
        {{ translationPrefix.include('SETTINGS_MFA.HOW_IT_WORKS.DESCRIPTION') | translate }}
      </div>
    </div>
  </div>
</div>

<div class="row" style="margin-top: 16px;">
  <div class="col-sm-12">
    <div class="form-group-title">
      <label>
        <span>{{ translationPrefix.include('SETTINGS_MFA.STEPS.TITLE') | translate }}</span>
      </label>
      <div>
        <ol>
          <li>{{ translationPrefix.include('SETTINGS_MFA.STEPS.STEP1') | translate }}</li>
          <li>{{ translationPrefix.include('SETTINGS_MFA.STEPS.STEP2') | translate }}</li>
          <li>{{ translationPrefix.include('SETTINGS_MFA.STEPS.STEP3') | translate }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>
