import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, Subject } from 'rxjs';

import { ClearInlineNotifications, SupportedNotification } from './notification';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public readonly notifications$: Observable<SupportedNotification>;

  private readonly notificationEmitter = new Subject<SupportedNotification>();

  public constructor() {
    this.notifications$ = this.notificationEmitter.asObservable();
  }

  public show(notification: SupportedNotification) {
    this.notificationEmitter.next(notification);
  }

  public clear(identifier?: string) {
    this.notificationEmitter.next(new ClearInlineNotifications(identifier));
  }
}
