import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TermsResource } from '@shared/disclaimer/terms';
import { filter, first, mergeMap, Observable, take } from 'rxjs';
import { CountryDetails, CountryResource } from '@shared/localization';
import { Notification, NotificationService } from '@shared/notifications';
import { CurrentUser, CurrentUserService } from '@security/user';


@Component({
  selector: 'ic-terms',
  templateUrl: './terms.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsComponent implements OnInit {
  public selectedCountryCode: string;
  public needAcceptance = false;
  public tracking: { accepted: boolean; countryCode: string };

  public currentUser$: Observable<CurrentUser>;
  public supportedCountries$: Observable<CountryDetails[]>;

  public constructor(
    private currentUser: CurrentUserService,
    private countryResource: CountryResource,
    private termsResource: TermsResource,
    private router: Router,
    private notification: NotificationService
  ) {
    this.subscribeToState();
  }

  public ngOnInit(): void {
    this.setDefaultsIfSignedIn();
  }

  public onCountrySelected(country: string) {
    this.selectedCountryCode = country;
  }

  public onAcceptTerms() {
    this.currentUser$
      .pipe(
        mergeMap(({ uuid }) => this.termsResource.accept(
          { userUuid: uuid, trackingAccepted: this.tracking.accepted }
        ))
      )
      .subscribe({
        next: () => this.onTermsAccepted(),
        error: () => this.onAcceptTermsFailed()
      });
  }

  public onRefuseTerms() {
    this.router.navigate(['/logout']);
  }

  private onTermsAccepted() {
    this.currentUser.refresh(true)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate(['/welcome']);
      });
  }

  private onAcceptTermsFailed() {
    this.notification.show(
      Notification.toast().asError({ messageKey: 'ERROR_ACCEPTING_TERMS' })
    );
  }

  private setDefaultsIfSignedIn() {
    this.currentUser$
      .pipe(
        filter(u => !!u),
        first()
      )
      .subscribe((user) => {
        this.selectedCountryCode = user.country;
        this.tracking = { accepted: true, countryCode: this.selectedCountryCode };
        this.needAcceptance = !user.termsAccepted;
      });
  }

  private subscribeToState() {
    this.currentUser$ = this.currentUser.ensureUser$;
    this.supportedCountries$ = this.countryResource.getCountries(true);
  }
}
