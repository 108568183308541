import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityModule } from '@security/security.module';
import { SharedModule } from '@shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ErrorsRoutingModule } from './errors-routing.module';
import { AppErrorComponent } from './components/app-error/app-error.component';


@NgModule({
  declarations: [
    AppErrorComponent
  ],
  imports: [
    CommonModule,
    ErrorsRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    SecurityModule
  ]
})
export class ErrorsModule { }
