import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-contact-sent',
  templateUrl: './contact-sent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSentComponent {

}
