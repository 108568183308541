import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailApiService } from '@security/change-email';
import { CurrentUserService, SessionService } from '@security/user';
import { NotificationService, Notification } from '@shared/notifications';


@Component({
  selector: 'ic-activate-new-email',
  template: `
		<ic-default-layout>
			<h1 class="page-header">
				{{ 'EMAIL_CHANGE_ACTIVATION_TITLE' | translate }}
			</h1>
		</ic-default-layout>
	`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivateNewEmailComponent implements OnInit {
  public constructor(
    private route: ActivatedRoute,
    private emailResource: EmailApiService,
    private currentUser: CurrentUserService,
    private session: SessionService,
    private notification: NotificationService
  ) { }

  public get token() {
    return this.route.snapshot.params['token'];
  }

  public ngOnInit(): void {
    this.emailResource
      .activateNewEmail(this.token)
      .subscribe({
        next: () => this.onEmailActivated(),
        error: (error) => this.onEmailActivationFailed(error)
      });
  }

  private async onEmailActivated() {
    this.notification.show(
      Notification.inline().asSuccess({
        messageKey: 'CHANGE_EMAIL_ACTIVATION_SUCCESS',
        showLink: true,
        linkText: 'LOGIN',
        linkUrl: '/'
      })
    );

    if (this.session.getToken()) { this.currentUser.logout(); }
  }

  private onEmailActivationFailed(error) {
    this.notification.show(
      Notification.inline().asError({
        messageKey: 'CHANGE_EMAIL_ACTIVATION_ERROR',
        showLink: true,
        linkText: 'CHANGE_EMAIL',
        linkUrl: '/email'
      })
    );
  }
}
