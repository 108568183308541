<h1>Imprimă</h1>
<div class="imprint">
	<p>
		<span class="imprint-header">ista România S.R.L.</span><br>
		Splaiul Independenței nr.294<br>
		Corp O2, Etaj 1, Sector 6<br>
		București, România
	</p>
	<br>

	<p>Tel.: +4021 315.98.24<br>
		Fax: +4021 317.22.09<br>
		Email: <a href="mailto:office@ista.ro">office@ista.ro</a><br>
		<a href="http://www.ista.ro" target="_blank">www.ista.ro</a>
	</p>

	<p>
		CUI: RO11417888<br>
		J40/424/1999
	</p>
	<br>
	<br>
	<p>Toate drepturile acestui website aparțin ista SE 2022</p>

</div>
