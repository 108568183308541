import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AlertInfo } from './alert-box.component';
import { filter, map, Observable, tap } from 'rxjs';
import { ClearInlineNotifications, InlineNotification, NotificationType } from './notification';
import { NotificationService } from './notification.service';


@Component({
  selector: 'ic-inline-notification',
  template: `
		<ic-alert-box [reserveSpace]="false" [fade]="true" [alertInfo]="data$ | async">
		</ic-alert-box>
	`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineNotificationComponent {
  @Input()
  public identifier: string;

  public data$: Observable<AlertInfo>;

  public constructor(
    private notification: NotificationService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.subscribeToNotifications();
  }

  private subscribeToNotifications() {
    this.data$ = this.notification
      .notifications$
      .pipe(
        filter(n => n instanceof InlineNotification || n instanceof ClearInlineNotifications),
        filter((n: InlineNotification) => !n.identifier || this.identifier === n.identifier),
        map((notification: InlineNotification | ClearInlineNotifications) => {
          if (notification instanceof InlineNotification) {
            return {
              class: this.getCssClass(notification.type),
              msgCode: notification.messageKey,
              message: notification.message,
              translationData: notification.messageParams,
              showRecoveryLink: notification.showLink,
              recoveryLink: notification.linkUrl,
              recoveryText: notification.linkText
            };
          }
          else { return null; }
        }),
        tap(() => this.changeDetector.markForCheck())
      );
  }

  private getCssClass(notificationType: NotificationType) {
    switch (notificationType) {
      case NotificationType.SUCCESS: {
        return 'success';
      }
      case NotificationType.ERROR: {
        return 'danger';
      }
      case NotificationType.WARNING: {
        return 'warning';
      }
      case NotificationType.INFO: {
        return 'info';
      }
    }
  }
}
