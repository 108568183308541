import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environment';
import { SendContactFormPayload } from '../models';


@Injectable({ providedIn: 'root' })
export class ContactApiService {
  public constructor(
    private http: HttpClient
  ) { }

  public sendUnsecured(payload: SendContactFormPayload) {
    return this.http.post(
      `${environment.restEndpoint}/unsecuredContact`,
      payload
    );
  }

  public sendSecured(payload: SendContactFormPayload) {
    return this.http.post(
      `${environment.restEndpoint}/securedContact/${payload.userUuid}`,
      payload
    );
  }
}
