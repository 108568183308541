<h1>Politica Cookies</h1>
<h2>Ce sunt cookie-urile?</h2>
<p>
	Cookie-urile sunt fragmente de text plasate in computerul dumneavoastra de catre site-urile pe care le vizitati.
	Aceasta tehnologie de stocare automata a datelor este foarte raspandita si se foloseste pentru a colecta informatii
	precum tipul de browser si sistemul de operare, pagina sursa, denumirea domeniului din care vizitatorul s-a conectat
	la site-ul ista connect, cu scopul de a intelege cum folosesc vizitatorii aceasta pagina de web. "Cookies" ne ajuta
	sa adaptam continutul website-ului in functie de nevoile dumneavoastra.
</p>

<h2>Cum si de ce folosim cookie-uri?</h2>
<p>
	Folosim cookies pentru a imbunatati utilizarea si functionalitatea site-urilor noastre si pentru a intelege mai bine
	modul in care vizitatorii utilizeaza aceste site-uri, precum si serviciile oferite de catre acestea. Stocarea de
	cookies pe calculatorul dumneavoastra ne ofera o modalitate usoara si convenabila pentru a personaliza sau a
	imbunatati experienta dumneavoastra pe site-urile noastre si pentru a face urmatoarea vizita mai placuta. Noi nu
	folosim cookies pentru a colecta informatii personale, cum ar fi numele dumneavoastra, cu toate acestea, putem face
	legatura intre informatiile continute intr-un cookie si informatiile dumneavoastra. cu caracter personal colectate
	prin alte mijloace (de exemplu, formularele de inregistrare).
</p>

<h2>Cum pot modifica setarile mele cookie?</h2>
<p>
	Puteti in orice moment modifica setarile dumneavoastra cookie (de exemplu, sa fiti alertati atunci cand cookie-urile
	sunt trimise catre computerul dumneavoastra), facand click pe link-ul de mai jos. Daca faceti acest lucru, va rugam
	sa aveti in vedere faptul ca este posibil sa nu va puteti inregistra pe site-urile noastre sau sa folositi alte
	functii care necesita inregistrarea sau colectarea de informatii.
</p>

<h2>Modifica setari Cookie</h2>
<p>
	Orice modificari la setarile dumneavoastra cookie vor fi salvate pana cand decideti sa faceti modificari
	suplimentare, in cazul in care v-ati razgandit. Retineti ca puteti accesa aceasta pagina si link-ul in orice moment,
	facand click pe "Politica Cookies" in subsolul acestui website.
</p>

<h2>Ce tipuri de Cookie-uri sunt folosite?</h2>
<p>
	Atunci cand vizitati acest site, urmatoarele tipuri de Cookie-uri sunt folosite.
</p>

<h2>Cookie-urile necesare</h2>
<p>
	Acestea sunt Cookie-urile strict necesare pentru operarea unui site web. Fara aceste cookie-uri, acest site nu va
	functiona corect.
</p>
<h2>Cookie-urile functionale</h2>
<p>
	Acestea sunt Cookie-urile care sunt setate pentru a imbunatati functionalitatea site-ului. De exemplu, Cookie-urile
	care amintesc continutul pe care l-ati vizionat anterior pe acest site sau adresa de e-mail si parola furnizate la
	inregistrare in timpul unei vizite anterioare pe acest site. Prin umare, utilizarea Cookie-urilor functionale ne
	permite sa va oferim continut adaptat intereselor dumneavoastra, nemai fiind necesara o noua inregistrare sau sa
	introduceti din nou anumite informatii in formularele de inregistrare atunci cand va intoarceti pe acest website.
</p>

<h2>Cookie-urile site-ului</h2>
<p>
	Acestea sunt Cookie-uri stabilite de catre acest site si pot fi citite numai de catre acest site.
</p>

<h2>Cookie-uri necesare</h2>
<table class="ro-policy-table">
	<thead>
	<th class="ro-policy-th">Name</th>
	<th class="ro-policy-th">Scope</th>
	</thead>
	<tbody>
	<tr>
		<td>ic.cookie-policy-accepted</td>
		<td>Salvează informații dacă politica de cookie-uri a fost acceptată de utilizator.</td>
	</tr>
	<tr>
		<td>locale</td>
		<td>Salvează preferințele de limbă.</td>
	</tr>
	<tr>
		<td>_pk_id.x.xxxx</td>
		<td>Utilizată pentru identificarea utilizatorilor care au reapărut.</td>
	</tr>
	<tr>
		<td>_pk_ses.x.xxxx</td>
		<td>Folosit pentru colectarea informațiilor despre paginile vizitate de utilizator.</td>
	</tr>
	</tbody>
</table>
<p>
	Ne rezervam dreptul de a face orice modificari si corectii asupra acestui document. Va rugam sa consultati aceasta
	pagina din cand in cand pentru a reciti aceste informatii.
</p>

<h2>Alte tehnologii similare</h2>
<p>
	Acest site face uz de tehnologii de urmarire ("Adrese IP", fisiere jurnal ", web beacons"), pentru a aduna anumite
	informatii, cum ar fi tipul de browser si sistemul de operare, pagina de trimitere, navigarea prin site-ul, domeniul
	de ISP, etc in scopul imbunatatirii utilizarii si functionalitatii site-urilor noastre si pentru a intelege mai bine
	modul in care vizitatorii utilizeaza site-urile noastre, precum si instrumentele si serviciile oferite. Tehnologiile
	de urmarire ne ajuta sa ne adaptam site-urile la nevoile dumneavoastra personale.
</p>

<h2>Adresa IP</h2>
<p>
	Exista posibilitatea sa urmarim Internet Protocol (IP) pentru (printre altele): depanarea tehnica, mentinerea
	sigurantei si securitatii site-ului, restrictionarea accesului la site-urile noastre pentru anumiti utilizatori, si
	intelegerea mai buna a modului in care site-urile noastre sunt utilizate. O adresa IP este un numar care este
	utilizat de computere in retea pentru a identifica computerul dumneavoastra de fiecare data cand va conectati la
	Internet.
</p>

<h2>Fisierele Jurnal</h2>
<p>
	Noi sau o terta parte in numele nostru putem colecta informatii in forma de fisiere jurnal care inregistreaza
	activitatea site-ului si aduna informatii statistice despre obiceiurile de navigare ale utilizatorilor. Aceste
	intrari sunt generate anonim, si ne ajuta sa adunam tipul de utilizator de browser si sistemul de operare,
	informatii despre sesiunea unui utilizator (cum ar fi URL-ul de pe care au venit, data si ora cand au vizitat
	site-ul, pagini de pe site-ul nostru pe care le-a vizitat si pentru cat timp), si alte date similare de navigatie
	sau click-stream. Noi folosim, de asemenea, informatii capturate in fisierul jurnal pentru studiile noastre interne
	de marketing, astfel incat sa putem imbunatati in mod constant si sa putem personaliza serviciile online pe care vi
	le oferim. Fisierele jurnal sunt utilizate numai intern si nu sunt asociate cu nici un anumit utilizator.
</p>

<h2>Web Beacons</h2>
<p>
	Exista posibilitatea sa folosim beacon-urile (sau GIF-uri clare) pe site-urile noastre sau sa le includem in
	e-mail-urile trimise catre dumneavoastra. Web beacons (de asemenea, cunoscut sub numele de "bug-uri web") sunt
	siruri mici de cod care ofera o metoda de livra o imagine grafica pe o pagina web sau intr-un mesaj de e-mail cu
	scopul de a transfera date inapoi la noi. Informatiile colectate prin intermediul web, beacon-urile pot include
	unele dintre informatiile descrise in sectiunea Adresa IP de mai sus, precum si informatii despre modul in care un
	utilizator raspunde la o campanie de e-mail (de exemplu, timpul in care un e-mail este deschis, unde intra
	utilizatorul din acel e-mail, etc). Noi folosim informatiile Web Beacons pentru diverse scopuri, inclusiv, dar nu
	limitat la, raportarea traficului pe site, numarul vizitatorilor unici, de publicitate si de audit de e-mail si
	raportare, precum si personalizare.
</p>

<h2>Gestionarea cookie-urilor/a preferintelor</h2>
<p>
	Dumneavoastra trebuie sa va asigurati ca setarile computerului dumneavoastra reflecta daca sunteti sau nu bucuros sa
	acceptati cookie-uri. Astfel, va puteti seta browser-ul de internet sa va avertizeze inainte sa acceptati
	cookie-urile sau il puteti seta sa le refuze instant. Nu este necesar sa utilizati cookie-urile ca sa puteti utiliza
	sau sa aveti acces la acest site, cu toate ca exista posibilitatea sa nu aveti acces la toate caracteristicile
	site-ului. Pentru informatii suplimentare trebuie sa utilizati butonul « help » din browser-ul dumneavoastra.
</p>

<h2>Actualizari</h2>
<p>
	Ne rezervam dreptul de a face orice completari sau modificari la aceasta notificare legala. Va rugam sa vizitati
	aceasta pagina din cand in cand pentru a va informa asupra acestora si a noilor informatii.
</p>
