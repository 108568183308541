import { Inject, Injectable } from '@angular/core';
import { icBrand } from './brand.factory';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  private brandLoadedBus = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public brandLoaded$ = this.brandLoadedBus.asObservable();

  public constructor(
    @Inject(icBrand) private brandName: string,
  ) { }

  public initialize() {
    if (this.brandName) {
      const assetsRev = +new Date();
      const styleTag = document.createElement('link');
      styleTag.rel = 'stylesheet';
      styleTag.type = 'text/css';
      styleTag.href = `/s2/brands/${this.brandName}/style.css?rev=${assetsRev}`;
      styleTag.onload = () => this.brandLoadedBus.next(true);
      styleTag.onerror = () => this.brandLoadedBus.next(false);

      document.body.appendChild(styleTag);
    }
  }
}
