import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { isStatusBypassed } from '@shared/common';
import { PublicPagesService, SessionService } from '@security/user';


enum HTTP_STATUS {
  NOT_AUTHENTICATED = 401,
  NOT_AUTHORIZED = 403
}

@Injectable()
export class HandleHttpUnauthorizedInterceptor implements HttpInterceptor {

  private readonly logoutStatusCodes = [
    HTTP_STATUS.NOT_AUTHENTICATED,
    HTTP_STATUS.NOT_AUTHORIZED,
  ];

  public constructor(
    private session: SessionService,
    private router: Router,
    private publicPages: PublicPagesService,
  ) { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const isBypassed = isStatusBypassed(request.headers)(error.status);

        if (isBypassed) {
          return throwError(() => error);
        }

        if (this.userShouldBeLoggedOut(error)) {
          this.logoutUser();
          return EMPTY;
        }

        return throwError(() => error);
      })
    );
  }

  private logoutUser() {
    this.session.destroy();

    setTimeout(() =>
      this.router.navigate(['/logout'])
    );
  }

  private userShouldBeLoggedOut(error: HttpErrorResponse) {
    return !this.publicPages.userIsOnPublicPage()
      && this.logoutStatusCodes.includes(error.status);
  }
}
