import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class EnsureTosAcceptedGuard implements CanActivate {
  public constructor(
    private router: Router,
    private currentUser: CurrentUserService
  ) { }

  public async canActivate() {
    const user = await firstValueFrom(this.currentUser.user$);

    if (!user?.termsAccepted) {
      setTimeout(() => {
        this.router.navigate(['/terms']);
      });
    }

    return user.termsAccepted;
  }

}
