<div class="wrapper">
  <div class="row">
    <div class="mfa-icon hidden-xs visible col-sm-1">
      <i class="fa fa-shield"></i>
    </div>

    <div class="col-xs-12 col-sm-11">
      <p><strong>{{ 'SETTINGS_MFA.SUBTITLE' | translate }}</strong></p>
      <p>{{ 'SETTINGS_MFA.SLOGAN' | translate }}</p>

      <a *ngIf="!hasAnyCredentials; else alreadyConfigured"
         class="btn btn-primary btn-change-settings-submit"
         [href]="otpUrl">
        <i class="fa fa-cog"></i>
        {{ translationPrefix.include('SETTINGS_MFA.SET_UP') | translate }}
      </a>

      <ng-template #alreadyConfigured>
        <a class="btn btn-primary btn-change-settings-submit"
           [href]="otpUrl">
          <i class="fa fa-cog"></i>
          {{ translationPrefix.include('SETTINGS_MFA.SET_ADDITIONAL') | translate }}
        </a>

        <button type="button" class="btn btn-warning btn-change-settings-submit"
          (click)="onDisabling()">
          <i class="fa fa-unlock"></i>
          {{ translationPrefix.include('SETTINGS_MFA.DISABLE') | translate }}
        </button>
      </ng-template>
    </div>
  </div>
</div>
