import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './logout';
import { UserRegistrationComponent } from './registration';


const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'register',
    component: UserRegistrationComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class SecurityRoutingModule { }
