import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgIncludeDirective } from '@shared/layout';

@Component({
  templateUrl: './terms_cz-cz.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsCZComponent { }

@Component({
  templateUrl: './terms_de-de.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsDEComponent { }

@Component({
  templateUrl: './terms_it-it.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsITComponent { }

@Component({
  templateUrl: './terms_pl-pl.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsPLComponent { }

@Component({
  templateUrl: './terms_ro-ro.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsROComponent { }

@Component({
  selector: 'ic-term',
  template: '<div ngInclude></div>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermComponent implements OnInit, OnChanges {
  @ViewChild(NgIncludeDirective, { static: true })
  public termsPlaceholder: NgIncludeDirective;

  @Input() public countryCode = 'DE';

  public ngOnInit() {
    this.loadTermsForSelectedCountry();
  }

  public ngOnChanges() {
    this.loadTermsForSelectedCountry();
  }

  private loadTermsForSelectedCountry() {
    const viewContainer = this.termsPlaceholder.viewContainer;
    viewContainer.clear();

    const componentType = this.findTypeByCountryCode(this.countryCode);

    if (!componentType) {
      this.countryCode = null;
      throw new Error(`No terms found for selected country code: ${this.countryCode}`);
    }

    viewContainer.createComponent(componentType);
  }

  private findTypeByCountryCode(country: string) {
    switch (country) {
      case 'CZ':
        return TermsCZComponent;
      case 'DE':
        return TermsDEComponent;
      case 'IT':
        return TermsITComponent;
      case 'PL':
        return TermsPLComponent;
      case 'RO':
        return TermsROComponent;
      default: {
        return null;
      }
    }
  }
}
