import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import { validateEmail } from '@shared/forms/validators';
import { RequestEmailChangePayload } from '@security/change-email/services/email-api.service';


enum FormKeys {
  NEW_EMAIL = 'newEmail',
  PASSWORD = 'password',
  UPDATE_ALERTING_EMAIL = 'updateAlertingEmail'
}

interface FormValue {
  [FormKeys.NEW_EMAIL]: string;
  [FormKeys.PASSWORD]: string;
  [FormKeys.UPDATE_ALERTING_EMAIL]: boolean;
}

@Component({
  selector: 'ic-email-change-form',
  templateUrl: './email-change-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailChangeFormComponent implements OnInit {
  @Input()
  public localizationPrefix = '';

  @Output()
  public changed = new EventEmitter<void>();
  @Output()
  public save = new EventEmitter<{ payload: RequestEmailChangePayload; updateAlertingEmail: boolean }>();

  public formGroup: FormGroup;
  public submitted = false;
  public FormKeys = FormKeys;
  public emailNotificationsAvailable: boolean;

  private subscriptions = new Subscription();

  public constructor(
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  @Input()
  public set emailNotificationsDisabled(isDisabled: boolean) {
    this.emailNotificationsAvailable = !isDisabled;
    this.adjustEmailAlertDependencies(!isDisabled);
  }

  public ngOnInit(): void {
    this.subscribeToFormChanges();
  }

  public onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) { return; }

    const value: FormValue = this.formGroup.value;
    const payload = {
      currentPassword: value[FormKeys.PASSWORD],
      newEmailAddress: value[FormKeys.NEW_EMAIL]
    };

    this.save.next({
      payload,
      updateAlertingEmail: value[FormKeys.UPDATE_ALERTING_EMAIL]
    });
  }

  public buildKey(key: string) {
    return !this.localizationPrefix
      ? key
      : `${this.localizationPrefix}.${key}`;
  }

  public controlHasError(controlName: FormKeys, validatorName?: string) {
    const formControl = this.formGroup.get(controlName);
    const givenNameIsInInvalidState = !!formControl.errors && (!validatorName || !!formControl.errors[validatorName]);

    // TODO: introduce a service for this
    return givenNameIsInInvalidState && (!formControl.pristine || this.submitted);
  }

  public reset() {
    this.submitted = false;

    this.formGroup.reset({}, { emitEvent: false });
    this.formGroup.markAsPristine();
  }

  private createForm() {
    this.formGroup = this.formBuilder.group({
      [FormKeys.NEW_EMAIL]: ['', [Validators.required, validateEmail()]],
      [FormKeys.PASSWORD]: ['', Validators.required],
      [FormKeys.UPDATE_ALERTING_EMAIL]: [null]
    });
  }

  private adjustEmailAlertDependencies(emailNotificationsActive: boolean) {
    const control = this.formGroup.get(FormKeys.UPDATE_ALERTING_EMAIL);

    if (emailNotificationsActive) { control.enable(); }
    else { control.disable(); }
  }

  private subscribeToFormChanges() {
    const subscription = this.formGroup.valueChanges
      .pipe(
        debounceTime(250)
      )
      .subscribe(() => this.changed.emit());

    this.subscriptions.add(subscription);
  }
}
