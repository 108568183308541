import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CredentialRepresentation } from '@security/mfa/models/credential-representation.model';
import { TranslationPrefix } from '@shared/localization';

@Component({
  selector: 'ic-mfa-credentials',
  templateUrl: './mfa-credentials.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfaCredentialsComponent {
  @Input()
  public credentials: CredentialRepresentation[];

  @Output()
  public removed = new EventEmitter<string>();

  public constructor(
    protected translationPrefix: TranslationPrefix
  ) {
  }

  public onRemoving(credentialId: string) {
    this.removed.next(credentialId);
  }
}
