<ic-mfa-instructions>
</ic-mfa-instructions>

<ng-container *ngIf="availableCredentials$ | async as credentials">
  <ic-set-up-mfa [hasAnyCredentials]="anyCredentialsAvailable$ | async"
                 (disableMfa)="onMfaDisabling()">
  </ic-set-up-mfa>

  <ic-mfa-credentials *ngIf="anyCredentialsAvailable$ | async"
                      [credentials]="credentials"
                      (removed)="onCredentialRemoving($event)">
  </ic-mfa-credentials>
</ng-container>
