import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-spinner',
  template: `
    <ngx-spinner
      bdColor="rgba(0,0,0,0.55)"
      color="#fff"
      type="ball-clip-rotate"
      size="medium"
      [fullScreen]="true">
    </ngx-spinner>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
}
