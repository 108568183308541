import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MfaService } from '@security/mfa';
import { TranslationPrefix } from '@shared/localization';

@Component({
  selector: 'ic-set-up-mfa',
  templateUrl: './set-up-mfa.component.html',
  styleUrls: ['./set-up-mfa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetUpMfaComponent {
  @Input()
  public hasAnyCredentials: boolean;

  @Output()
  public disableMfa = new EventEmitter<void>();

  public constructor(
    private mfa: MfaService,
    protected translationPrefix: TranslationPrefix,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  public get otpUrl() {
    return this.mfa.getOtpSetupUrl(this.document.defaultView.location.href);
  }

  public onDisabling() {
    this.disableMfa.next();
  }
}
