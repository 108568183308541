import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { map } from 'rxjs';
import { ServerCredentialRepresentation } from '../models/server-credential-representation.model';
import { CredentialRepresentation } from '../models/credential-representation.model';
import { MfaCredentialType } from '../models/mfa-credential-type.model';

@Injectable({ providedIn: 'root' })
export class MfaApiService {
  public constructor(
    private http: HttpClient
  ) {
  }

  public getAvailableCredentials() {
    return this.http.get<ServerCredentialRepresentation[]>(
      `${environment.restEndpoint}/user/credentials`
    ).pipe(
      map(result => result
        .map(item => ({
          id: item.id,
          label: item.userLabel,
          type: this.parseCredentialType(item.type),
          creationDate: new Date(item.createdDate)
        } as CredentialRepresentation)
        )
        .filter(item => item.type === MfaCredentialType.OTP)
      )
    );
  }

  public removeCredentialById(credentialId: string) {
    const params = { ids: credentialId };

    return this.http.delete<ServerCredentialRepresentation[]>(
      `${environment.restEndpoint}/user/credentials`,
      { params }
    );
  }

  public removeCredentialsByType(type: MfaCredentialType) {
    const params = { type };

    return this.http.delete<ServerCredentialRepresentation[]>(
      `${environment.restEndpoint}/user/credentials`,
      { params }
    );
  }

  private parseCredentialType(type: string) {
    switch (type) {
      case 'otp': {
        return MfaCredentialType.OTP;
      }
      case 'mail': {
        return MfaCredentialType.MAIL;
      }
      default: {
        return MfaCredentialType.OTHER;
      }
    }
  }
}
