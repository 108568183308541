import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CredentialRepresentation, MfaCredentialType, MfaService } from '@security/mfa';
import { TranslationPrefix } from '@shared/localization';
import { NotificationService, Notification } from '@shared/notifications';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'ic-manage-mfa',
  templateUrl: './manage-mfa.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageMfaComponent {
  public availableCredentials$: Observable<CredentialRepresentation[]>;
  public anyCredentialsAvailable$: Observable<boolean>;

  public constructor(
    private mfa: MfaService,
    private prefixValue: TranslationPrefix,
    private notifications: NotificationService
  ) {
    this.availableCredentials$ = this.subscribeToAvailableCredentials();
    this.anyCredentialsAvailable$ = this.subscribeToAnyCredentialsAvailable();
  }

  @Input()
  public set translationPrefix(prefix: string) {
    this.prefixValue.apply(prefix);
  }

  public onMfaDisabling() {
    this.mfa.disableMfa().subscribe({
      next: () => this.onRemoveActionSucceeded(),
      error: () => this.onRemoveActionFailed()
    });
  }

  public onCredentialRemoving(credentialId: string) {
    this.mfa.removeCredential(credentialId).subscribe({
      next: () => this.onRemoveActionSucceeded(),
      error: () => this.onRemoveActionFailed()
    });
  }

  private onRemoveActionSucceeded() {
    this.mfa.refresh();

    this.notifications.show(
      Notification.toast().asSuccess({
        messageKey: this.prefixValue.include('SETTINGS_MFA.CREDENTIALS.REMOVE_SUCCESS')
      })
    );
  }

  private onRemoveActionFailed() {
    this.notifications.show(
      Notification.toast().asError({
        messageKey: this.prefixValue.include('SETTINGS_MFA.CREDENTIALS.REMOVE_FAILURE')
      })
    );
  }

  private subscribeToAvailableCredentials() {
    return this.mfa.availableCredentials$;
  }

  private subscribeToAnyCredentialsAvailable() {
    return this.mfa.availableCredentials$.pipe(
      map(credentials => credentials || []),
      map(credentials =>
        !!credentials.find(c => c.type === MfaCredentialType.OTP)
      )
    );
  }
}
