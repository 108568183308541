import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, delay, firstValueFrom, lastValueFrom, Subscription, take } from 'rxjs';
import { AppErrorService } from '../../services/app-error.service';
import { NotificationService, Notification } from '@shared/notifications';

@Component({
  template: `
    <ic-default-layout>
      <div class="app-error-wrapper">
        <ic-inline-notification [identifier]="PLACEHOLDER_ID">
        </ic-inline-notification>
      </div>
    </ic-default-layout>
  `,
  styles: [`
    .app-error-wrapper {
      max-width: 550px;
      margin: 36px auto;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppErrorComponent implements OnInit, OnDestroy {
  public readonly PLACEHOLDER_ID = 'appErrorPlaceholder';

  private subscriptions = new Subscription();

  public constructor(
    private notifications: NotificationService,
    private appError: AppErrorService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.redirectIfNoError();
    this.subscribeToAppError();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private async redirectIfNoError() {
    const error = await lastValueFrom(
      this.appError.error$.pipe(take(1))
    );

    if (!error) { this.router.navigate(['/']); }
  }

  private subscribeToAppError() {
    const onError = this.appError.error$
      .pipe(
        delay(250)
      )
      .subscribe({
        next: (error) => {
          this.notifications.show(
            Notification.inline().asError({
              placeholderId: this.PLACEHOLDER_ID,
              messageKey: error.messageKey,
              message: error.message,
              showLink: error.showLink,
              linkUrl: error.linkUrl,
              linkText: error.linkText
            })
          );
        }
      });

    this.subscriptions.add(onError);
  }
}
