import { Inject, Injectable, Optional } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  shareReplay, switchMap, of
} from 'rxjs';

import { CurrentUser } from '../models/current-user.model';
import { SessionService } from './session.service';
import { KeycloakService } from 'keycloak-angular';
import { CurrentUserApiService } from './current-user-api.service';


@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  public user$: Observable<CurrentUser>;

  private userBus = new BehaviorSubject<void>(undefined);

  public constructor(
    private currentUserResource: CurrentUserApiService,
    private session: SessionService,
    private keycloak: KeycloakService
  ) {
    this.user$ = this.subscribeToUser();
  }

  public get ensureUser$() {
    return this.user$.pipe(filter(u => !!u));
  }

  public logout() {
    this.session.destroy();
    return this.keycloak.logout(window.location.origin);
  }

  public refresh(hard?: boolean) {
    if (hard) { this.user$ = this.subscribeToUser(); }
    else { this.userBus.next(); }

    return this.user$;
  }

  private subscribeToUser() {
    return this.userBus.pipe(
      switchMap(() => this.currentUserResource.fetchUser()),
      catchError(() =>
        // NOTE: instead of breaking the pipeline we rather wanna return the null object
        // and cache it by shareReplay() fn below
        of(null)
      ),
      shareReplay(1)
    );
  }
}
