<h1>Informativa sulla privacy</h1>
<p>ista Italia rispetta le disposizioni relative alla riservatezza dei dati personali che le sono richiesti per
	utilizzare i Servizi offerti. Ai sensi dell’art. 13 del D. lgs. 196/03, “Codice in materia di protezione dei dati
	personali” (nel seguito anche “Codice”), ista Italia desidera fornirle le opportune informazioni sull’utilizzo dei
	suoi dati personali.
	Per quanto non espressamente descritto dalla presente Informativa, troveranno applicazione le definizioni contenute
	nelle Condizioni Generali di Contratto di ista Italia.</p>
<h2>1. Origine dei dati personali</h2>
<p>I dati personali oggetto di trattamento sono forniti dal Cliente in fase di attivazione dei servizi (servizio di
	contabilizzazione calore/acqua o altri servizi) di ista Italia; altrimenti possono essere acquisiti attraverso la
	nostra rete commerciale ovvero da archivi aziendali o pubblici, anche ai fini di cui al successivo punto 2, nello
	svolgimento dell’attività economica di ista Italia, ma sempre nel rispetto delle disposizioni di legge.</p>
<h2>2. Finalità del trattamento</h2>
<p>I Dati Personali sono raccolti e trattati per finalità connesse o strumentali all’attività di ista Italia e dunque:
<ol type="a">
	<li>per attività relative ad informative precontrattuali sollecitate dal Cliente e per ogni ulteriore attività
		strumentale alla sottoscrizione e/o attivazione di Prodotti e Servizi;
	</li>
	<li>per l’erogazione dei Servizi di prodotti e servizi offerti da ista Italia direttamente o attraverso Società del
		Gruppo ista SE ovvero Partners commerciali, compresi i servizi di supporto ed ancillari eventualmente richiesti
		dal Cliente; la gestione tecnica, amministrativa e contabile di reclami e contenziosi nonché per valutare
		l’adesione da parte di ista Italia a successive proposte contrattuali sollecitate dal Cliente, nelle forme e con
		il limiti di cui alla successiva lett. d); l’invio di informazioni e comunicazioni di servizio; la consegna,
		attivazione e manutenzione dei prodotti, Servizi e/o apparati etc. acquistati o forniti in comodato d’uso; la
		partecipazione a programmi premio o di loyalty di ista Italia; per migliorare i Prodotti e Servizi ed adeguare
		l’offerta alle esigenze della Clientela;
	</li>
	<li>per l’adempimento di obblighi previsti da normative comunitarie e nazionali, incluse leggi, regolamenti pro
		tempore vigenti e provvedimenti anche impartiti da Autorità a ciò legittimate, nonché per finalità correlate
		all’accertamento e repressione dei reati;
	</li>
	<li>per le necessarie attività relative al monitoraggio dell’andamento delle relazioni con la Clientela e di
		controllo dei rischi di credito e frodi connessi ai Servizi prestati. Per attivare e mantenere attivi i Servizi,
		sono utilizzati alcuni dati personali provenienti da archivi o registri pubblici relativi ad eventuali protesti,
		iscrizioni o trascrizioni pregiudizievoli (quali pignoramenti, procedure concorsuali, sequestri, ipoteche,
		domande giudiziali) e dati di visura e di bilancio. Tali dati sono acquisiti attraverso l’accesso ai sistemi
		informativi di società autorizzate e sono trattati, laddove necessario, esclusivamente ai fini della verifica
		sulla affidabilità e puntualità nei pagamenti. I dati pubblici potranno anche essere combinati con altre
		informazioni statistiche e con dati acquisiti in relazione al contratto da attivare e consentiranno la
		determinazione di un giudizio sintetico (score) di affidabilità creditizia.
		ista Italia potrà, inoltre, trattare i dati sullo stato e sulla puntualità del Cliente nei pagamenti dei Servizi
		forniti anche in passato: tali informazioni, una volta acquisite da ista Italia, saranno conservate e trattate
		per la tutela delle ragioni di credito di ista Italia, al fine della valutazione di eventuali ulteriori adesioni
		e/o proposte contrattuali sottoscrivibili dal Cliente;
	</li>
	<li>per marketing diretto, ossia per l'invio di materiale pubblicitario, di vendita diretta di comunicazione
		commerciale o per il compimento di ricerche di mercato o, relativa ai Prodotti e/o Servizi offerti da ista
		Italia; tale attività potrà essere eseguita mediante l’invio di materiale pubblicitario/informativo/promozionale
		e/o di inviti di partecipazione ad iniziative, eventi ed offerte volte a premiare i Clienti, effettuato con
		modalità “tradizionali” (a titolo esemplificativo posta cartacea e/o chiamate da operatore), ovvero mediante
		sistemi “automatizzati” di contatto (a titolo esemplificativo SMS e/o MMS, chiamate telefoniche senza
		l’intervento dell’operatore, e-mail , fax, applicazioni interattive), ai sensi dell’art. 130 c. 1 e 2 del
		Codice;
	</li>
	<li>per marketing diretto, ossia per l'invio di materiale pubblicitario, di vendita diretta, di comunicazione
		commerciale o per il compimento di ricerche di mercato, relativa ai prodotti e/o servizi offerti da Società del
		Gruppo ista Intenational GmbH società controllanti, controllate e/o collegate ovvero Partner commerciali,
		operanti principalmente nei settori della contabilizzazione calore/energia della variabili ai quali ista Italia
		potrà comunicare/cedere i dati acquisiti; tale attività potrà essere eseguita mediante l’invio di materiale
		pubblicitario/informativo/promozionale e/o di inviti di partecipazione ad iniziative, eventi ed offerte volte a
		premiare i Clienti, effettuato con modalità “tradizionali” (a titolo esemplificativo posta cartacea e/o chiamate
		da operatore), ovvero mediante sistemi “automatizzati” di contatto (a titolo esemplificativo SMS e/o MMS,
		chiamate telefoniche senza l’intervento dell’operatore, e-mail, fax, applicazioni interattive), ai sensi
		dell’art. 130 c. 1 e 2 del Codice;
	</li>
	<li>per la rilevazione delle preferenze di consumo e l’analisi anche in forma personalizzata, automatizzata od
		elettronica, delle informazioni acquisite attraverso l’utilizzo dei prodotti e/o servizi utilizzati.
	</li>
</ol>
<h2>3. Modalità, logiche del trattamento e tempi di conservazione</h2>
<p>Il trattamento è effettuato anche con l’ausilio di mezzi elettronici o comunque automatizzati ed è svolto da ista
	Italia e/o da terzi, di cui ista Italia può avvalersi per memorizzare, gestire e trasmettere i dati stessi. Il
	trattamento dei dati sarà effettuato con logiche di organizzazione ed elaborazione dei dati dei Clienti correlate
	alle finalità di cui alla presente informativa, comunque, in modo da garantire la sicurezza e la riservatezza dei
	dati. Nell’ambito dei servizi di assistenza telefonica, i dati dei Clienti sono gestiti con procedure informatizzate
	idonee a permettere all’operatore di rispondere in modo efficiente e mirato alle richieste del Cliente, anche sulla
	base delle caratteristiche dei Prodotti e Servizi dallo stesso acquisiti. I dati personali per le finalità di cui al
	punto 2 lett. e), f) e g) saranno trattati nel rispetto della normativa vigente in materia. Decorso il periodo
	previsto dalla legge i dati potranno in ogni caso essere trattati esclusivamente per finalità amministrative e/o per
	dare esecuzione agli obblighi derivanti dal quadro regolamentare e normativo pro-tempore applicabile e nel rispetto
	degli specifici obblighi di legge sulla conservazione dei dati.</p>
<h2>4. Categorie di soggetti ai quali i dati possono essere comunicati o che possono venirne a conoscenza in qualità di
	Responsabili o di Incaricati</h2>
<p>Per il perseguimento delle finalità sopra indicate, ista Italia potrebbe avere necessità di comunicare, in Italia e
	all’estero, compresi paesi non appartenenti all’Unione Europea, i dati personali dei Clienti a soggetti terzi per
	dare compiuta esecuzione al contratto, in adempimento di obblighi di legge o per lo svolgimento di attività
	strumentali alla prestazione dei servizi richiesti. Detti soggetti appartengono alle seguenti categorie:
<ol type="a">
	<li>autorità pubbliche e organi di vigilanza e controllo, quando ciò occorra per la concessione di sovvenzioni,
		contributi, sussidi ed ausili di qualsiasi genere connessi alla prestazione dei servizi di ista Italia ovvero
		quando sia richiesto da specifiche previsioni normative, regolamentari e autorizzative anche in ordine
		all’applicazione delle agevolazioni tariffarie e fiscali previste dalle vigenti disposizioni emanate in materia
		dalle competenti Autorità per le forniture di energia elettrica nelle abitazioni di residenza;
	</li>
	<li>soggetti che svolgono per conto di ista Italia compiti di natura tecnica o organizzativa; soggetti che
		effettuano servizi di acquisizione, lavorazione ed elaborazione dei dati necessari per la fruizione dei Servizi
		per la Clientela; soggetti che forniscono servizi per la gestione della infrastruttura tecnologica di ista
		Italia; soggetti che svolgono attività di trasmissione, imbustamento, trasporto e smistamento delle
		comunicazioni al Cliente; soggetti che svolgono attività di assistenza alla Clientela (es. call center etc.);
		soggetti che svolgono attività di archiviazione e data entry; studi e società nell’ambito dei rapporti di
		assistenza e consulenza anche legale; soggetti che svolgono adempimenti di controllo, revisione e certificazione
		delle attività poste in essere da Ista Italia anche nell’interesse dei propri Clienti e utenti; Istituti Bancari
		e società emittenti le carte di credito; altri operatori del settore energia per la gestione dei relativi
		rapporti;
	</li>
	<li>società esterne, anche estere, che operano nel settore della concessione dei finanziamenti incluse dilazioni di
		pagamento, quando consentito dalla legislazione vigente, per finalità di prevenzione e controllo del rischio di
		insolvenza, controllo delle frodi e di tutela del credito, nonché soggetti operanti nell’ambito del recupero
		credito anche stragiudiziale (Società e/o Studi Legali), Istituti Bancari o Società di factoring in caso di
		cessione dei crediti;
	</li>
	<li>società del gruppo ista SE controllanti, controllate e collegate per finalità amministrativo-contabili di
		gestione e controllo.
		I soggetti appartenenti alle categorie sopra riportate trattano i dati personali come distinti Titolari del
		trattamento o in qualità di Responsabili o Incaricati appositamente nominati da ista Italia.
	</li>
</ol>
<p>Tutti i dipendenti, consulenti, interinali e/o ogni altra “persona fisica”, ex art. 30 del Codice, che svolgono la propria attività sulla base delle istruzione ricevute da ista Italia sono nominati “Incaricati del trattamento”.
Agli Incaricati o ai Responsabili, eventualmente designati, ista Italia impartisce adeguate istruzioni operative, con particolare riferimento all’adozione delle misure minime di sicurezza, al fine di poter garantire la riservatezza e la sicurezza dei dati.</p>
<h2>5. Natura del consenso</h2>
<p>Il conferimento dei dati personali da parte del Cliente, è necessario per la stipulazione e l’esecuzione dei rapporti
	contrattuali, per l’adesione ai programmi premio o per adempiere ad obblighi di legge. Un eventuale rifiuto
	comporterebbe l’impossibilità per ista Italia di attivare il servizio richiesto, di prestare al Cliente gli
	ulteriori Servizi richiesti,
	In relazione ai trattamenti di cui al punto 2, lett. e) f) e g) della presente Informativa, il consenso al
	trattamento dei dati da parte del Cliente è invece facoltativo e sempre revocabile.
	ista Italia potrà inviare al Cliente comunicazioni commerciali relative a prodotti e/o servizi analoghi a quelli a
	lui già forniti, ai sensi dell’art. 130 c. 4 del Codice, utilizzando le coordinate di posta elettronica da lui
	indicate in tali occasioni, alle quali il Cliente potrà opporsi con le modalità indicate al punto 8).</p>
<h2>6. Ulteriori Informazioni</h2>
<p>ista Italia garantisce che la sicurezza e la riservatezza dei dati del Cliente saranno tutelate mediante adeguate
	misure di protezione, in base a quanto disposto dagli artt. 31 e ss. del Codice e dall’Allegato “B”, al fine di
	ridurre i rischi di distruzione o perdita, anche accidentale, dei dati, di accesso non autorizzato, o di trattamento
	non consentito o non conforme alle finalità della raccolta.</p>
<h2>7. Utilizzo dei cookies</h2>
<p>I termini e le condizioni della presente informativa si applicano all’utilizzo delle informazioni disponibili su
	questo sito web gestito da ista SE (“ista”). ista ha la sua sede principale a Grugaplatz 2, 45131 Essen, Germania.
	Al fine di rendere i propri servizi il più possibile efficienti e semplici da utilizzare questo Sito fa uso di
	cookies. Pertanto, quando si visita il Sito, viene inserita una quantità minima di informazioni nel dispositivo
	dell'Utente, come piccoli file di testo chiamati "cookie", che vengono salvati nella directory del browser Web
	dell'Utente. Esistono diversi tipi di cookie, ma sostanzialmente lo scopo principale di un cookie è quello di far
	funzionare più efficacemente il Sito e di abilitarne determinate funzionalità.
	Questo sito utilizza Piwik, un Open-Source-Software per le analisi statistiche degli accessi dei visitatori. Piwik
	utilizza i "Cookies". I Cookies hanno lo scopo di analizzare l'uso del sito web o di un sito web di ottimizzazione.
	L’informazione generata dai Cookies viene trasferita al server. L’indirizzo IP viene immediatamente reso anonimo
	dopo essere stato processato e salvato. Le informazioni generate dal cookie sull’utilizzo del sito non vengono
	trasmesse a terzi. Per tutti gli utilizzi di questo sito e dei documenti disponibili a riguardo è necessario
	conoscere e rispettare la legislazione locale applicabile. Eventuali aggiornamenti della presente Informativa
	saranno immediatamente resi disponibili sul sito web di ista Italia e attraverso i canali commerciali di ista
	Italia. Ulteriori informazioni sono reperibili all’indirizzo: <a href="https://www.ista.com/it/privacy-policy/"
																	 target="_blank">https://www.ista.com/it/privacy-policy/</a>
</p>
<h2>8. Il Titolare e i Responsabili del trattamento</h2>
<p>Il Titolare del trattamento è ista Italia, con sede legale, attualmente, in Via Roberto Lepetit 40, 20020 Lainate
	(MI). Responsabile del trattamento dei dati dei Clienti rappresentato nella persona del legale rappresentante
	pro-tempore ovvero del responsabile del trattamento di volta in volta in carica, presso cui il Cliente potrà
	esercitare i diritti di cui all’art. 7 del Codice.</p>
<h2>9. Diritti dell’interessato</h2>
<p>Ai sensi dell’art. 7 del Codice, il Cliente ha, tra gli altri, anche il diritto di conoscere, in ogni momento, quali
	sono i suoi dati e come essi vengono utilizzati, nonché di farli aggiornare, integrare, rettificare o di chiederne
	la cancellazione, il blocco ed opporsi al loro trattamento, se effettuato in violazione di legge. Per i trattamenti
	di cui al punto 2 lett. e), f) e g) il Cliente potrà sempre revocare il consenso ed esercitare il diritto di
	opposizione al marketing diretto (in forma “tradizionale” e “automatizzata”). L’opposizione, in assenza di
	indicazione contraria, verrà riferita tanto alle comunicazioni tradizionali quanto a quelle automatizzate. I diritti
	di cui al citato art. 7, anche in relazione ai dati trattati per l’attività di profilazione, possono essere
	esercitati su richiesta del Cliente con le modalità rese note dal Servizio Clienti o sul sito web.</p>
<p>La presente informativa privacy è stata aggiornata a dicembre 2015.</p>
