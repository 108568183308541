import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CountryFeatureService } from '@security/user';


@Component({
  template: ` `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeComponent implements AfterViewInit {

  public constructor(
    private router: Router,
    private countryFeature: CountryFeatureService
  ) { }

  public ngAfterViewInit(): void {
    this.redirectToDefaultFeatureIfAvailable();
  }

  private async redirectToDefaultFeatureIfAvailable() {
    if (await this.countryFeature.isConsumptionEnabled()) { this.tryRedirectingToUrl('/user/consumption'); }
    else if (await this.countryFeature.isDocumentEnabled()) { this.tryRedirectingToUrl('/user/documents'); }
  }

  private tryRedirectingToUrl(url: string) {
    if (this.router.url === url) { return; }

    this.router.navigate([url]);
  }
}
