import { HttpHeaders } from '@angular/common/http';

const bypassKey = 'BypassInterceptorForStatuses';

export const buildBypassHttpStatuses = (statuses: number[]) => new HttpHeaders({
  [bypassKey]: serializeStatuses(statuses)
});

export const isStatusBypassed = (headers: HttpHeaders) => (status: number) => {
  if (!headers || !headers.get(bypassKey)) { return false; }

  const statuses = unserializeStatuses(headers.get(bypassKey));

  return statuses.includes(status);
};

const serializeStatuses = (statuses: number[]) => statuses
  .map(status => status.toString())
  .reduce((a, b) => `${a},${b}`);

const unserializeStatuses = (value: string) => value
  .split(',')
  .map(status => +status);
