import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DenyAnonymousGuard, UserRole, requireRole } from '@security/user';
import { EnsureTosAcceptedGuard } from '@security/user/guards/ensure-tos-accepted.guard';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ActivateNewEmailComponent } from './components/activate-new-email/activate-new-email.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { UserActivationComponent } from './components/user-activation/user-activation.component';

const routes: Routes = [
  {
    path: 'password',
    data: { backLinkTarget: '/welcome' },
    canActivate: [DenyAnonymousGuard, EnsureTosAcceptedGuard, requireRole(UserRole.PM)],
    component: ChangePasswordComponent
  },
  {
    path: 'email/token',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/email'
      },
      {
        path: ':token',
        data: { backLinkTarget: '/welcome' },
        component: ActivateNewEmailComponent
      }
    ]
  },
  {
    path: 'email',
    data: { backLinkTarget: '/welcome' },
    canActivate: [DenyAnonymousGuard, EnsureTosAcceptedGuard, requireRole(UserRole.PM)],
    component: ChangeEmailComponent
  },
  {
    path: 'userActivation/token/:token', // TODO: investigate why two different URLs for the same component
    data: { backLinkTarget: '/welcome' },
    component: UserActivationComponent
  },
  {
    path: 'activate/token/:token', // TODO: investigate why two different URLs for the same component
    data: { backLinkTarget: '/welcome' },
    component: UserActivationComponent
  }
];


@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class UserSecurityRoutingModule { }
