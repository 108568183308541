import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  template: `
		<ic-default-layout>
			<ic-change-password></ic-change-password>
		</ic-default-layout>
	`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent { }
