import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgIncludeDirective } from '@shared/layout';


@Component({
  templateUrl: './imprint_cz-cz.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImprintCZComponent { }

@Component({
  templateUrl: './imprint_de-de.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImprintDEComponent { }

@Component({
  templateUrl: './imprint_it-it.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImprintITComponent { }

@Component({
  templateUrl: './imprint_pl-pl.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImprintPLComponent { }

@Component({
  templateUrl: './imprint_ro-ro.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImprintROComponent { }

@Component({
  selector: 'ic-imprint-localized',
  template: '<div ngInclude></div>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImprintLocalizedComponent implements OnInit, OnChanges {
  @ViewChild(NgIncludeDirective, { static: true })
  public termsPlaceholder: NgIncludeDirective;

  @Input() public countryCode: string;

  public ngOnInit() {
    this.loadTermsForSelectedCountry();
  }

  public ngOnChanges() {
    this.loadTermsForSelectedCountry();
  }

  private loadTermsForSelectedCountry() {
    const viewContainer = this.termsPlaceholder.viewContainer;
    viewContainer.clear();

    const componentType = this.findTypeByCountryCode(this.countryCode);

    if (!componentType) {
      this.countryCode = null;
      throw new Error(`No imprint data found for selected country code: ${this.countryCode}`);
    }

    viewContainer.createComponent(componentType);
  }

  private findTypeByCountryCode(country: string) {
    switch (country) {
      case 'CZ':
        return ImprintCZComponent;
      case 'DE':
        return ImprintDEComponent;
      case 'IT':
        return ImprintITComponent;
      case 'PL':
        return ImprintPLComponent;
      case 'RO':
        return ImprintROComponent;
      default: {
        return null;
      }
    }
  }
}
