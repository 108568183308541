import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  templateUrl: './policy_DE.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyDEComponent { }

@Component({
  templateUrl: './policy_GB.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyGBComponent { }

@Component({
  templateUrl: './policy_HR.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyHRComponent { }

@Component({
  templateUrl: './policy_IT.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyITComponent { }

@Component({
  templateUrl: './policy_PL.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyPLComponent { }

@Component({
  templateUrl: './policy_RO.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyROComponent { }
