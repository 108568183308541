import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppErrorComponent } from './components/app-error/app-error.component';


const routes: Routes = [
  {
    path: 'error',
    component: AppErrorComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ErrorsRoutingModule { }
