import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ic-app-footer',
  template: `
    <div class="container-fluid footer" [ngClass]="{ 'is-branded': isBranded }">
      <a [routerLink]="['/imprint']" translate="IMPRINT"></a> |
      <a [routerLink]="['/terms']" translate="TERMS"></a> |
      <a [routerLink]="['/contact']" translate="CONTACT.TITLE"></a>
      <span *ngIf="isBranded" class="powered-by">
        powered by &copy;ista connect
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFooterComponent {
  @Input()
  public isBranded: boolean;
}
