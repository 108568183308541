import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLinkWithHref } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SecurityModule } from '@security/security.module';
import { SharedModule } from '@shared/shared.module';
import { UserSecurityRoutingModule } from './user-security-routing.module';
import { ContentModule } from 'src/app/content/content.module';
import { ActivateNewEmailComponent } from './components/activate-new-email/activate-new-email.component';
import { UserActivationComponent } from './components/user-activation/user-activation.component';
import { UserActivationFormComponent } from './components/user-activation-form/user-activation-form.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

@NgModule({
  imports: [
    CommonModule,
    SecurityModule,
    RouterLinkWithHref,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    ContentModule,
    UserSecurityRoutingModule,
  ],
  declarations: [
    ActivateNewEmailComponent,
    UserActivationComponent,
    UserActivationFormComponent,
    ChangeEmailComponent,
    ChangePasswordComponent
  ]
})
export class UserSecurityModule {
}
