import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { LanguageSelectionService } from '@shared/localization';
import { icBrand } from '@shared/branding';


@Component({
  selector: 'ic-default-layout',
  templateUrl: './default-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultLayoutComponent {
  public isBranded = !!this.brandName;
  public locale$ = this.languageSelection.locale$;

  public constructor(
    private languageSelection: LanguageSelectionService,
    @Inject(icBrand) private brandName: string
  ) {
  }
}
