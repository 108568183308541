import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CurrentUser, CurrentUserService } from '@security/user';
import { CountryDetails, CountryResource } from '@shared/localization';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'ic-imprint',
  template: `
		<ic-default-layout>
			<div *ngIf="(currentUser$ | async) === null" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-top-15">
				<ic-country-selector
					[countries]="supportedCountries$ | async"
					(selected)="onCountrySelected($event)">
				</ic-country-selector>
			</div>
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [ngClass]="{ 'padding-top-15' : currentUser$ | async }">
				<ic-imprint-localized *ngIf="selectedCountryCode"
					[countryCode]="selectedCountryCode">
				</ic-imprint-localized>
			</div>
		</ic-default-layout>
	`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImprintComponent implements OnInit {
  public selectedCountryCode: string;

  public currentUser$: Observable<CurrentUser>;
  public supportedCountries$: Observable<CountryDetails[]>;

  public constructor(
    private currentUser: CurrentUserService,
    private countryResource: CountryResource
  ) {
    this.subscribeToState();
  }

  public ngOnInit(): void {
    this.setDefaultsIfSignedIn();
  }

  public onCountrySelected(country: string) {
    this.selectedCountryCode = country;
  }

  private setDefaultsIfSignedIn() {
    this.currentUser.ensureUser$
      .pipe(take(1))
      .subscribe(user => {
        this.selectedCountryCode = user.country;
      });
  }

  private subscribeToState() {
    this.currentUser$ = this.currentUser.ensureUser$;
    this.supportedCountries$ = this.countryResource.getCountries(true);
  }
}
