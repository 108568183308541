import { Injectable } from '@angular/core';

import { FileDownloadPayload } from './file-download';


@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  public download({ content, filename }: FileDownloadPayload) {
    const fakeAnchor = document.createElement('a');
    const objectUrl = URL.createObjectURL(content);

    fakeAnchor.href = objectUrl;
    fakeAnchor.download = filename;

    fakeAnchor.click();
    URL.revokeObjectURL(objectUrl);
  }
}
