<h1>Imprint</h1>
<div class="imprint">
	<p>ista Italia S.r.l.<br>
		Via Lepetit, 40<br>
		20045 Lainate (MI)<br>
		Italia
	</p>

	<p>
		Tel.:02 9628 831 <br>
		Fax:02 9670 4186 <br>
		Email: <a href="mailto:info.italia@ista.com">info.italia@ista.com</a><br>
		Internet: <a href="http://www.ista.com/it" target="_blank">www.ista.com/it</a>
	</p>

	<p>
		Registro imprese di Varese <br>
		REA 227285
	</p>

	<p>Partita IVA 02659330126</p>

	<p>Tutto il contenuto di questo sito web è © di ista SE 2022</p>
</div>
