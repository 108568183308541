import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { LabelValuePair } from '@shared/common';
import { ContactService } from '../../services';
import { ContactFormInitialValue, SendContactFormPayload } from '../../models';


@Component({
  template: `
		<ic-default-layout>
			<div class="contact-container">
				<br>
				<h1 class="contact-title">
					{{ 'CONTACT.TITLE' | translate }}
				</h1>
				<br>

				<ic-contact-form *ngIf="!sent; else thankYouBox"
	                 [countries]="countries$ | async"
		             [initialValue]="initialValue$ | async"
                     (saving)="onSending($event)">
				</ic-contact-form>

				<ng-template #thankYouBox>
					<ic-contact-sent></ic-contact-sent>
				</ng-template>
			</div>
		</ic-default-layout>
	`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent implements OnInit {
  public countries$: Observable<LabelValuePair[]>;
  public initialValue$: Observable<ContactFormInitialValue>;

  public sent: boolean;

  public constructor(
    private contact: ContactService,
    private changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.contact.initialize();
    this.subscribeToState();
  }

  public onSending(payload: SendContactFormPayload) {
    this.contact
      .send(payload)
      .subscribe({
        next: () => this.onContactSent()
      });
  }

  private onContactSent() {
    this.sent = true;
    this.changeDetector.markForCheck();
  }

  private subscribeToState() {
    this.countries$ = this.contact.countries$;
    this.initialValue$ = this.contact.initialValue$;
  }
}
