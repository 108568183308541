import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { filter, firstValueFrom, Subscription } from 'rxjs';

import { NotificationService } from './notification.service';
import { NotificationLiveTime, NotificationType, ToastNotification } from './notification';

@Component({
  selector: 'ic-toasts',
  template: `
		<!-- TOASTS COMPONENT -->
	`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastsComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  public constructor(
    private notifications: NotificationService,
    private toastr: ToastrService,
    private translation: TranslateService
  ) { }

  public ngOnInit(): void {
    this.subscribeToNotifications();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private subscribeToNotifications() {
    const onHandled = this.notifications
      .notifications$
      .pipe(
        filter(n => n instanceof ToastNotification)
      )
      .subscribe(async (notification: ToastNotification) => {
        const message = !!notification.message
          ? notification.message
          : await this.localizeMessage(notification.messageKey, notification.messageParams);
        const config = { ...this.buildTimeoutRelatedProperties(notification.liveTime) };

        switch (notification.type) {
          case NotificationType.SUCCESS: {
            this.toastr.success(message, '', config);
            break;
          }
          case NotificationType.ERROR: {
            this.toastr.error(message, '', config);
            break;
          }
          case NotificationType.WARNING: {
            this.toastr.warning(message, '', config);
            break;
          }
        }
      });

    this.subscription.add(onHandled);
  }

  private buildTimeoutRelatedProperties(liveTime?: NotificationLiveTime): Partial<IndividualConfig> {
    const config: Partial<IndividualConfig> = {
      closeButton: true
    };

    switch (liveTime) {
      case NotificationLiveTime.LONG: {
        return Object.assign(config, { timeOut: 20 * 1000 });
      }
      case NotificationLiveTime.UNTIL_TAPPED: {
        return Object.assign(config, { disableTimeOut: true });
      }
      default:
      case NotificationLiveTime.NORMAL: {
        return config;
      }
    }
  }

  private async localizeMessage(messageKey: string, messageParams?: { [key: string]: string }): Promise<string> {
    return firstValueFrom(this.translation.get(messageKey, messageParams));
  }
}
