import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environment';


const oauthTokenHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: 'Basic aWJwb3J0YWw6bmU2SkdFeXJKWks=',
};

const OAUTH = {
  GRANT_TYPES: {
    PASSWORD: 'PASSWORD',
    BEARER: 'BEARER',
  },
  SCOPES: {
    REST_API: 'REST_API',
    GRANT_TOKEN_BY_BEARER: 'GRANT_TOKEN_BY_BEARER',
    DOWNLOAD: 'DOWNLOAD',
  },
};

interface TokenResponse {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  private tokenEndpointUrl = environment.restEndpoint + '/oauth/token';

  public constructor(
    private http: HttpClient
  ) { }

  public createSession({ username, password }) {
    const payload = {
      grant_type: OAUTH.GRANT_TYPES.PASSWORD,
      scope: OAUTH.SCOPES.REST_API + ' ' + OAUTH.SCOPES.GRANT_TOKEN_BY_BEARER,
      username,
      password,
    };

    return this.http
      .post<TokenResponse>(
        this.tokenEndpointUrl,
        new HttpParams({ fromObject: payload }),
        { headers: oauthTokenHeaders }
      );
  }

  public createDownloadBearer(accessToken) {
    const requestData = {
      grant_type: OAUTH.GRANT_TYPES.BEARER,
      scope: OAUTH.SCOPES.DOWNLOAD,
      bearer: accessToken,
    };

    return this.http
      .post<TokenResponse>(
        this.tokenEndpointUrl,
        new HttpParams({ fromObject: requestData }),
        { headers: oauthTokenHeaders }
      );
  }
}
